import React from "react";
import {
  FaArrowRight,

  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaLinkedinIn,
  FaMapMarkedAlt,
  FaMapMarker,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterOne = () => {
  return (
    <>
      {/* ================== Footer One Start ==================*/}




      <footer className='footer-area bg-black bg-cover '>
      
        <div className='container pt-5'>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src='assets/img/logo.png' alt='img' />
                </div>
                <div className='details'>
<p> SimpliTax CMS Pvt Ltd. is a Tax Advisory & Financial Consulting company, fully managed by specialized professionals who are expert in their respective fields. It has been founded with a vision to provide one stop solutions to business, tax, regulatory, legal and allied consulting needs of clients from India and multinational companies operating/wanting to operate, in India. </p>
              
                </div>
              </div>
            </div>
          
            <div className='col-lg-4 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Quick links</h4>
                <ul>
                <li>
                    <Link to='/about'>
                      <FaArrowRight /> About us
                    </Link>
                  </li>
                  <li>
                    <Link to='/contact'>
                      <FaArrowRight /> Contact us
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaArrowRight /> GST Returns
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaArrowRight /> Income Tax Returns
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaArrowRight /> Payroll
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaArrowRight /> Tax Compliances
                    </Link>
                  </li>
               </ul>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='widget widget-recent-post'>
                <h4 className='widget-title'> Contact us </h4>
                <h6 className='text-white'><b> Corporate Office </b></h6>
                <p className='mt-3'>
                    <FaMapMarkerAlt /> A-25, First Floor, Sector 34, Noida - 201301, U.P., INDIA
                  </p>
                  <h6 className='text-white'><b> Branch Office </b> </h6>
                <p className='mt-3'>
                    <FaMapMarkerAlt /> Tower 8A, Ground Floor, Cyberhub, Gurugram, Haryana 122002 INDIA
                  </p>
                <p className='mt-3'>
                    <FaPhoneAlt /> +91 120 4314400, +91 9650264848
                  </p>
                  <p className='mt-2'>
                    <FaEnvelope />  info@simplitax.com
                  </p>
                  <ul className='social-media'>
                    <li>   <Link to='https://www.facebook.com/Simplitaxcms' target='_blank'>
                        <FaFacebookF />  </Link> </li>
                    <li>  <Link to='https://twitter.com/TaxSimpli' target='_blank'> <FaTwitter /> </Link> </li>
                    <li> <Link to='https://www.instagram.com/simplitax/' target='_blank'> 
                        <FaInstagram />
                      </Link>
                    </li>
                    <li>
                    <Link to='https://www.linkedin.com/in/simpli-tax-cms-pvt-ltd-11b4bb145' target='_blank'> 
                        <FaLinkedin/>
                      </Link>
                    </li> 
                  </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>© Yoursitename 2023 | All Rights Reserved</p>
              </div>
              <div className='col-md-6 text-lg-end'>
                <a href='#'>Trams &amp; Condition</a>
                <a href='#'>Privacy Policy</a>
                <a href='#'>Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterOne;
