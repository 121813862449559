import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle,  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const PublicLimitedCompanyRegistration = () => {
  return (
    <>
<NavbarTwo/>
        <Breadcrumb title={"Public Limited Company Registration"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> Public Limited Company Registration </h4>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Most popular form of Business entity in case of big businesses </li>
        <li> <FaCheckCircle /> No restriction on maximum no. of members and shareholders </li>
        <li> <FaCheckCircle /> Flexibility of addition and deletion of Directors and Shareholders </li>
        <li> <FaCheckCircle /> Most suitable for funding from Investors, PE firms, Banks </li>
        <li><FaCheckCircle/> Flexibility of raising funds by issue of Debentures/Bonds  </li> 
        <li><FaCheckCircle/> No restriction on the transferability of the shares of a Public company </li> 
        <li><FaCheckCircle/> Free to invite public for subscription i.e., a Public Limited Company Registration can issue a Prospectus. </li> 
                                  </ul>
                           </div>
                    <div className='col-lg-12 col-12 text-center'>
                      <h4 className='my-5'> Inclusions in this Package </h4>
                    </div>
                    <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' /> Digital Signatures Certificates (DSC) of 3 Directors </p></div>
                     <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Director Identification Nos. (DIN) of 3 Directors </p></div>
                       <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' /> Company name approval certificate </p></div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' /> Digital set of MOA & AOA </p> </div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Incorporation certificate </p> </div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' /> PAN & TAN Registration </p> </div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Company stamp </p> </div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' /> Assistance in opening current account in Bank </p> </div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' /> Shares Certificates </p> </div>
                         <div className='col-lg-12 col-12 text-center'>
                      <h4 className='my-5'> Time involved in the process </h4>
                    </div>
                    <div className='col-lg-6 col-md-6'> <p> <FaCheckCircle className='blue-icon' />    Approx. 15-18 working days till getting Incorporation certificate </p></div>
                    <div className='col-lg-6 col-md-6'> <p> <FaCheckCircle className='blue-icon' />  Approx. 8 working days in getting PAN/TAN </p></div>
                    <div className='col-lg-6 col-md-6'> <p> <FaCheckCircle className='blue-icon' />  Approx. 5 working days in opening bank account </p></div>


                    <div className='col-lg-12 col-12 my-5'>
                     <h4 className='text-center'> Document Required for Public Limited Company </h4>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Copy of PAN card of all Directors </li>
        <li> <FaCheckCircle /> Copy of Address proof of all Directors (Any one of Passport or Voter id or Aadhar card) </li>
        <li> <FaCheckCircle /> Proof of Business premises where company will be registered. (It can be from home also) </li>
        <li> <FaCheckCircle /> In case of rented premises- Copy of rent agreement + Latest electricity bill in name of landlord + NOC. </li>
        <li> <FaCheckCircle /> In case of owned premises- Copy of sale deed + Latest electricity bill in name of owner + NOC. </li>
        <li> <FaCheckCircle /> 2 Colored passport size photograph of all Directors </li>
        <li> <FaCheckCircle /> Copy of any utility bill or bank statement in name of Directors. </li>
        <li><FaCheckCircle/> Mobile no., email ids of all Directors, 2-3 proposed name of company, objects of company. </li>
        <li><FaCheckCircle/> List of Shareholders and percentage of shares held by them. </li>
                                  </ul>
                    </div>




                     {/* CTA START */}

<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> Public Limited Company Registration </h5>
                </div>
                <div>
                  <p> Starts from </p>
                  <h5><img src='../assets/img/inr.png' alt='Indian Rupee' className='img-fluid' /> 20,000/- </h5>
                  <p> All inclusive fees </p>
                </div>
   <div>
                  <Link to='/contact' className='btn btn-primary'> Enquire Now </Link>
                </div>
              </div>
  </div>

  {/* CTA END */}

               </div>
            </div>
          </div>

          <div
        className='faq-area  bg-cover pd-top-90 pd-bottom-110'
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className='container'>
          <div className='row'>
 <div  className='col-xl-12 col-lg-12'
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-4 mt-lg-0'>
              <h2 className='title text-center'> FAQS </h2>
              
              </div>
              <div
                className='accordion accordion-inner style-2 accordion-icon-left mt-3'
                id='accordionExample'
              >
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    > How many Directors and Shareholders are required for starting Public Ltd Company? </button> </h2>
  <div id='collapseOne' className='accordion-collapse collapse show' aria-labelledby='headingOne'
        data-bs-parent='#accordionExample' >
                    <div className='accordion-body'> <p> Minimum 3 Directors and 7 Shareholders/members.Can be even family members or relatives or friends.Should be major. No restriction on maximum no. of members </p> </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
  <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
    data-bs-target='#collapseTwo' aria-expanded='false'
                      aria-controls='collapseTwo'
                    > Q: What is the minimum authorized and paid up share capital with which Public Ltd can be started? </button>
                  </h2>
<div id='collapseTwo' className='accordion-collapse collapse' aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                      <p> Rs 500,000. </p> 
                    </div>
                  </div>
                </div>



                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseThree' aria-expanded='false' aria-controls='collapseThree'
> What significance is of authorized and paid up share capital? </button> </h2>
  <div  id='collapseThree' className='accordion-collapse collapse' aria-labelledby='headingThree'
      data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                  <p> Fees to be paid to ROC depend upon amount of authorized capital. In case authorized capital is less and company needs more money in the business, it has to increase authorized share capital. Authorized capital signifies maximum capital which can be raised by company. Paid up capital signifies how many shares have been actually held by shareholders and paid for same. Paid up capital can be less than authorized share capital </p>
                    </div>
                  </div>
                </div>

   <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFour'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingFour' aria-expanded='false'
                      aria-controls='collapseheadingFour'
                    > What are the steps involved in public co. registration </button> </h2>
                  <div
                    id='collapseheadingFour'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingheadingFour'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    <ul className='single-list-inner style-check style-check mb-3'>
                            <li><FaCheckCircle/> Firstly, Apply for Digital signature of all Directors, </li>
                            <li><FaCheckCircle/> Secondly apply for DIN of all Directors </li>
                            <li><FaCheckCircle/> Thirdly apply for name approval/reservation, then file MOA, AOA and apply for incorporation. Finally get the certificate of registration. </li>
                        </ul>
                      
                   </div>
                  </div>
                </div>





  <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFive'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingFive' aria-expanded='false'
      aria-controls='collapseheadingFive'> Once company is registered what other formalities are required before starting operations?  </button>
                  </h2>
    <div id='collapseheadingFive' className='accordion-collapse collapse' aria-labelledby='headingheadingFive'
           data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                    <ul className='single-list-inner style-check style-check mb-3'>
                            <li><FaCheckCircle/> Open a current account in bank and deposit paid up share capital money. </li>
                            <li><FaCheckCircle/> Then obtain GST Registration for your type of business </li>
                        </ul>
  </div>
                  </div>
                </div>


                
  <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingSix'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingSix' aria-expanded='false'
      aria-controls='collapseheadingSix'> What are the periodical compliances which need to be done by Public Ltd Company?  </button>
                  </h2>
    <div id='collapseheadingSix' className='accordion-collapse collapse' aria-labelledby='headingheadingSix'
           data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                      <p> Normally, a public ltd company has to do following compliances: </p>
                      <ul className='single-list-inner style-check style-check mb-3'>
    <li><FaCheckCircle/> Accounting or Book keeping (On daily basis)</li>
    <li><FaCheckCircle/> GST returns to be filed online monthly </li>
 <li><FaCheckCircle/> TDS returns of Salary paid and vendor payment is to be filed quarterly, if applicable </li>
  <li><FaCheckCircle/> Tax audit has to be got done by CA and audit report needs to be collected. </li>
  <li><FaCheckCircle/>  Balance sheet and Profit and Loss account and other financials need to be prepared and maintained. </li>
  <li><FaCheckCircle/> Minimum 4 Board meetings need to be held in one year </li>
  <li><FaCheckCircle/> Minimum 1 AGM needs to be held in a year </li>
  <li><FaCheckCircle/> Other secretarial compliances like maintaining minute book, registers, sending notices etc </li>
  <li><FaCheckCircle/> Income tax return of PublicLtd co. needs to be filed online annually before 30th September </li>
  <li> <FaCheckCircle/> ROC returns (approx. 4 in nos.) needs to be filed annually within 30 and 60 days of AGM </li>
  <li> <FaCheckCircle/> Other need based compliances. </li>
  <li> <FaCheckCircle/> Advance tax needs to be paid periodically </li> 
    </ul>
  </div>
                  </div>
                </div>


                
  <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingSeven'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingSeven' aria-expanded='false'
      aria-controls='collapseheadingSeven'> Do I need to file returns and do other compliance even if there are no operations in the company? </button>
                  </h2>
    <div id='collapseheadingSeven' className='accordion-collapse collapse' aria-labelledby='headingheadingSeven'
           data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                      <p> Yes, you need to file NIL returns, prepare balance sheet and profit and loss account and also get auditor’s certificate as well as complete all secretarial compliances. </p>
  </div>
                  </div>
                </div>
               </div>
            </div>
          </div>
        </div>
  </div>
 <FooterOne/>



    
    </>
  )
}

export default PublicLimitedCompanyRegistration