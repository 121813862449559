import React from "react";


const ContactAreaThree = () => {
  return (
    <>
      {/* ========================= contact Area Three start =========================*/}
      <div className='price-process-area pd-top-120 pd-bottom-90  bgbanner'>
        <div className='contact-inner-2 '>
          <div className="container">
          <div className='row my-5'>
  <div className='col-lg-6 ' data-aos='fade-right' data-aos-delay='200' data-aos-duration='1500' >
        <img class="w-100" src="assets/img/about/12.png" alt="img" />
            </div>
            <div
              className='col-lg-6'
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0'>
              <h2 className='title mb-4'>
                Why you should not delay further
                </h2>
                <div className='media mb-3'>
                  <div className='media-left me-3'>
                  <i className="fa-solid fa-circle-check fa-2x thmclr"></i>
                  </div>
                  <div className='media-body'>
                    <h5> 1% interest/month </h5>
            <p className='mb-0'> 1% interest/month on your tax liability until ITR is filed. </p>
                  </div>
                </div>
                <div className='media mb-3'>
                  <div className='media-left me-3'>
                  <i className="fa-solid fa-circle-check fa-2x thmclr"></i>
                  </div>
                  <div className='media-body'>
                    <h5> Refund will be delayed </h5>
                    <p className='mb-0'>
                    Late filing = Late processing = Delayed refund
                    </p>
                  </div>
                </div>
                <div className='media'>
                  <div className='media-left me-3'>
                  <i className="fa-solid fa-circle-check fa-2x thmclr"></i>
                  </div>
                  <div className='media-body'>
                    <h5>Risk of Notice </h5>
                    <p className='mb-0'> You may receive notice from the Income Tax Department for not filing ITR
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

      {/*========================= contact-inner Three end =========================*/}
    </>
  );
};

export default ContactAreaThree;
