import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle, } from "react-icons/fa";

const FilingItr7 = () => {
  return (
    <>
    
    <NavbarTwo/>
        <Breadcrumb title={"Filing ITR 7"} />
    
    
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> ITR-7 </h4>
    <p> The ITR-7 income tax form is to be filed by individuals or companies that are required to submit their returns under the following sections: </p>
           <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Section 139(4A) - Under this section, returns can be filed by those individuals who receive income from any property that is held for the purpose of charity or religion in the form of a trust or legal obligation </li>
        <li> <FaCheckCircle /> Section 139(4B) - Under this section, returns are to be filed by political parties provided their total income earned is above the non-taxable limit  </li>
                            </ul>
                            <p> Section 139(4C) - Under this section, returns are to filed by the following entities: </p>
                        
                            <ul className='single-list-inner style-check style-check mb-3'>
                            <li> <FaCheckCircle />  Any institution or association mentioned under Section 10(23A) </li> 
                            <li> <FaCheckCircle /> Any association involved with scientific research </li> 
                            <li> <FaCheckCircle /> Any institution mentioned in Section 10(23B) </li>
                            <li> <FaCheckCircle /> Any news agency </li>
                            <li> <FaCheckCircle /> Any fund, medical institution or educational institution </li>
                            <li> <FaCheckCircle /> Section 139(4D) - Under this section, returns are to be filed by entities such as colleges, universities or any other such institution wherein income returns or loss are not required to be provided in accordance with other provisions outlined in this section. </li>
                                </ul>


                        
                  
                </div>
              </div>
            </div>
          </div>
          {/* ===================== Service Details Area End ===================== */}
    
    
       
        <FooterOne/>
        </>
  )
}

export default FilingItr7