import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";

const FilingItr6 = () => {
  return (
    <>
    
    <NavbarTwo/>
        <Breadcrumb title={"Filing ITR 6"} />
    
    
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> ITR-6 </h4>
    <p> The ITR-6 form is to be used only by Companies, except those companies or organizations that claim tax exemptions as per Section 11. </p>

      </div>
              </div>
            </div>
          </div>
          {/* ===================== Service Details Area End ===================== */}
    
    
       
        <FooterOne/>
        </>
  )
}

export default FilingItr6