import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle,  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const PrivateLimitedCompanyRegistration = () => {
  return (
    <>


<NavbarTwo/>
        <Breadcrumb title={"Private Limited Company Registration"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> Private Limited Company Registration </h4>
                        <h6><b> Step 1: Acquiring DSC or Digital Signature Certificate of Each Director of Proposed Company </b> </h6>
 <p> The first step is to apply for the Digital Signature Certificate or the DSC of Each Director of the proposed company. This is according to The Information Act of 2000, which demands valid digital signature on all documents submitted. Affixing a DSC on each document is the safest way one can authenticate and sign each documents, on behalf of the company. </p>
 <h6><b> Step 2: Acquiring the DIN or Director Identification Number </b> </h6>
 <p> The second step to be followed in the process of company registration in India is to acquire the Director Identification Number (DIN). Every director of the proposed company needs to have a proper identification number. Therefore, acquiring the Director Identification Number is mandatory.
DIN is valid for lifetime and once any Director has obtained DIN, He is not required to apply for DIN again even if becomes director, in any other company.
To get the DIN, you need to fill up the e-form DIN-1 and upload on MCA’s website </p>
<p> Further, in case there are any changes in DIN info of a director, or in case it needs to be updated, for instance like personal details, change of address, etc., then the director can intimate the changes by submitting the DIN-4 form. </p>
<h6><b> Step 3: Applying for Company Name Registration
 </b> </h6>
 <p> Now you have to apply for a company name to register it. This is the third step for completing the registration process of a company. This not only includes the registration of a company name, it also incorporates the registration of the company at a genuine address/ notice of physical location of a company, and also the notice of appointment of company managers, directors, and the company secretary. Also, the qualification of shares and the pay will be clearly depicted here.
Form 1A: This is the application form which is present for the availability of a new company name or changing the existing name. While suggesting new names and applying for name approval, we have to follow the naming guidelines prescribed by the MCA. Normally, similar name to existing company and vague names are not approved. Further, the name of the proposed company should also provide a glimpse of the type of business which will be undertaken by the proposed company. Further, in case any name applied by us is rejected by the MCA, then we again have one more chance to provide fresh names without any additional fees. However, if our second chance is also rejected then a fresh application can be filed subject to payment of fees again. </p>
<h6><b> Step 4: Applying for Incorporation of company </b> </h6>
<p> In a final step, following forms are submitted online to MCA for requesting MCA to issue a final certificate of registration:
 </p>
 <p> Form SPICe, SPICe MOA, SPICe AOA. </p>
 <h6><b> Step 5: Apply for PAN, TAN in Name of New Company
 </b> </h6>
 <p> Along with the Incorporation Certificate of a registering Company, MCA now also allots PAN and TAN of the new company. </p>
 <h6><b> Step 6: Open Current Account in Bank
 </b> </h6>
 <p> We provide help in preparing necessary Board Resolutions for opening the Current Account of the company with any Govt./ Private Bank.
</p>
<h6> <b> Step 7: File Intimation to ROC about Appointment of First Auditors of Company
 </b></h6>
 <p> This is another crucial statutory requirement to appoint the First Auditors of the company, within 1 month of incorporating a new company and Filing the relevant Form with MCA, to intimate the ROC about appointment of First Auditors of the company. </p>
                   
                           </div>
                    <div className='col-lg-12 col-12 text-center'>
                      <h4 className='my-5'> Inclusions in this Package </h4>
                    </div>
                    <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' /> Digital Signatures Certificates (DSC) of 2 Directors  </p></div>
                     <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Director Identification Nos.(DIN) of 2 Directors  </p></div>
                       <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Company name approval certificate </p></div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Digital copy set of MOA& AOA </p> </div>
                         
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Incorporation certificate </p> </div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Company Stamp, PAN & TAN Nos. intimations </p> </div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Filing of Auditor Addition Form ADT-1 </p> </div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Assistance in opening current account in Bank </p> </div>
                         <div className='col-lg-12 col-12 text-center'>
                      <h4 className='my-5'> Time involved in the process </h4>
                    </div>
                    <div className='col-lg-12 col-md-12'> <p> <FaCheckCircle className='blue-icon' /> Usual time involved is between 08-10 working days in incorporating a new company </p></div>


                    <div className='col-lg-12 col-12 my-5'>
                     <h4 className='text-center'> Document Required for One Persion Company </h4>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Self-certified Copy of Pan Card of all Directors– 2 copies </li>
        <li> <FaCheckCircle /> Four Colored passport size photograph of each Director </li>
        <li> <FaCheckCircle /> Copy of any utility bill or bank statement in name of Directors. </li>
        <li> <FaCheckCircle /> Mobile & Email of all Directors </li>
        <li> <FaCheckCircle /> 2-3 proposed Name & Objects of Co. </li>
        <li> <FaCheckCircle /> Copy of Business proof of premises, where company will be registered </li>
        <li> <FaCheckCircle /> Self-certified Copy of Address Proof all Directors– 2 copies - Any one of Passport/Voter id/Driving license/Aadhar card </li>
        <li> <FaCheckCircle /> In case of rented premises- Rent Agreement + Latest electricity bill in name of landlord + NOC. </li>
        <li><FaCheckCircle/>  In case of owned premises- Copy of Sale Deed + Latest electricity bill in name of Owner </li>
                                  </ul>
                    </div>




                     {/* CTA START */}

<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> One Person Company Registration </h5>
                </div>
                <div>
                  <p> Starts from </p>
                  <h5><img src='../assets/img/inr.png' alt='Indian Rupee' className='img-fluid' /> 15,000/- </h5>
                  <p> All inclusive fees </p>
                </div>
   <div>
                  <Link to='/contact' className='btn btn-primary'> Enquire Now </Link>
                </div>
              </div>
  </div>

  {/* CTA END */}

               </div>
            </div>
          </div>

          <div
        className='faq-area  bg-cover pd-top-90 pd-bottom-110'
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className='container'>
          <div className='row'>
 <div  className='col-xl-12 col-lg-12'
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-4 mt-lg-0'>
              <h2 className='title text-center'> FAQS </h2>
              
              </div>
              <div
                className='accordion accordion-inner style-2 accordion-icon-left mt-3'
                id='accordionExample'
              >
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >  How many Directors and Shareholders are required for starting Private Limited Company? </button> </h2>
  <div id='collapseOne' className='accordion-collapse collapse show' aria-labelledby='headingOne'
        data-bs-parent='#accordionExample' >
                    <div className='accordion-body'> <p> Minimum 2 Directors and 2 Shareholders.Can be even family members or relatives or friends.Should be major. </p> </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
  <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
    data-bs-target='#collapseTwo' aria-expanded='false'
                      aria-controls='collapseTwo'
                    > What is the minimum authorized and paid up share capital with which pvt co. can be started? </button>
                  </h2>
<div id='collapseTwo' className='accordion-collapse collapse' aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                      <p> Rs 100,000 </p> 
                    </div>
                  </div>
                </div>



                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseThree' aria-expanded='false' aria-controls='collapseThree'
> What significance is of authorized and paid up share capital? </button> </h2>
  <div  id='collapseThree' className='accordion-collapse collapse' aria-labelledby='headingThree'
      data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                    <ul className='single-list-inner style-check style-check mb-3'>
                        <li><FaCheckCircle /> Govt. Fees payable to ROC depends upon amount of authorized capital. In case authorized capital is less and Private Limited Company requires more money in the business, then it will have to increase its authorized share capital. </li>
                        <li><FaCheckCircle /> Authorized capital signifies maximum capital which can be raised by company. Paid up capital signifies how many shares have been actually held by shareholders and paid for by its shareholders. Paid up capital can be less than authorized share capital. </li>

                 </ul>
                    </div>
                  </div>
                </div>

   <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFour'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingFour' aria-expanded='false'
                      aria-controls='collapseheadingFour'
                    > Once company is registered India what other formalities are required before starting operations? </button> </h2>
                  <div
                    id='collapseheadingFour'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingheadingFour'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <p> Once a new company is incorporated, it has to open its current account in bank and deposit paid up share capital money. </p>
                      <p> And thereafter obtain the GST Registration for your type of Business operations, </p> 
                   </div>
                  </div>
                </div>





  <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFive'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingFive' aria-expanded='false'
      aria-controls='collapseheadingFive'> What are the periodical compliances which need to be done by Private Limited Companies?  </button>
                  </h2>
    <div id='collapseheadingFive' className='accordion-collapse collapse' aria-labelledby='headingheadingFive'
           data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                    <p> Normally, a Pvt. Ltd. company has to do following compliances: </p>
                      <ul className='single-list-inner style-check style-check mb-3'>
                        <li><FaCheckCircle/> Accounting or Book keeping (On daily basis) </li>
                        <li><FaCheckCircle/> GST returns to be filed online on monthly basis </li>
                        <li><FaCheckCircle/> TDS returns of Salary paid and vendor payment is to be filed quarterly, if applicable </li>
  <li><FaCheckCircle/> Tax audit has to be got done by CA and audit report needs to be collected. </li>
  <li><FaCheckCircle/>  Balance sheet and Profit and Loss account and other financials need to be prepared and maintained. </li>
  <li><FaCheckCircle/> Minimum 4 Board meetings need to be held in one year </li>
  <li><FaCheckCircle/> Minimum 1 AGM needs to be held in a year </li>
  <li><FaCheckCircle/> Other secretarial compliances like maintaining minute book, registers, sending notices etc.  </li>
  <li><FaCheckCircle/> Income tax return of pvt ltd co. needs to be filed online annually by 30th September
 </li>
 <li><FaCheckCircle/> ROC returns (approx. 2-4 in nos.) need to be filed annually within 30 and 60 days of AGM
 </li>
 <li><FaCheckCircle/> other need based compliances
 </li>
 <li><FaCheckCircle/> Advance tax needs to be paid periodically
 </li>

    </ul>
  </div>
                  </div>
                </div>


                
  <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingSix'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingSix' aria-expanded='false'
      aria-controls='collapseheadingSix'> Q: Do I need to file returns and do other compliance even if there are no operations in the company? </button>
                  </h2>
    <div id='collapseheadingSix' className='accordion-collapse collapse' aria-labelledby='headingheadingSix'
           data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                      <p> Yes, you need to file NIL returns, prepare balance sheet and profit and loss account and also get auditor’s certificates. </p>
                 
    
  </div>
                  </div>
                </div>


                

              </div>
            </div>
          </div>
        </div>
  </div>
 <FooterOne/>



    
    </>
  )
}

export default PrivateLimitedCompanyRegistration