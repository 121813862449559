import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle,  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const PfRegistration = () => {
  return (
   <>  
<NavbarTwo />
        <Breadcrumb title={"Provident Fund Registration"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
              <div className='col-lg-12 qna_section'>
                        <h4 className='pb-4'> Obtaining Proivident fund registration (PF Number) </h4>
                      
<p> If you are running a business enterprise, even as a sole proprietor, employing more than 20 employees whose basic salary + DA, is below Rs. 15,000/- per month, then you should apply for Registration under Employees’ Provident Fund Scheme 1952. </p>
<p> Even those employees, who are drawing salary more than 15,000/- per month, can opt to get themselves covered under EPF Scheme. </p>
<p> The present rate of PF contribution is 12% of monthly Basic Salary + DA, payable equally both by each covered Employee & Employer. </p>
<h5> List of Documents/ Info required for PF Registration: </h5>
 <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Proforma for PF Coverage application : on letter head of business concern </li>
        <li> <FaCheckCircle /> Attested copy of PAN card of the applicant </li>
        <li> <FaCheckCircle /> Attested copy of Address Proof of the applicant </li>
        <li> <FaCheckCircle /> Copy of cancelled cheque of the business entity, confirming the bankers of business concern </li> 
        <li> <FaCheckCircle /> Copy of any recent work order obtained by applicant </li> 
        <li> <FaCheckCircle /> List of 20 employees (with names, DOB, Fathers name & Addresses) </li>
                                  </ul>


                           </div>

                     {/* CTA START */}

<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> Looking for best professional services at competitive price? </h5>
                </div>
             
   <div>
                  <Link to='/contact' className='btn btn-primary'> Enquire Now </Link>
                </div>
              </div>
  </div>

  {/* CTA END */}

               </div>
            </div>
          </div>

 <FooterOne/>
   
   </>
  )
}

export default PfRegistration