import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import { FaCheckCircle } from 'react-icons/fa';

const GstReturnandTheirDueDates = () => {
  return (
    <>
    <NavbarTwo />
    <Breadcrumb title={"GST Return and Their Due Dates"} />


    {/* ===================== Service Details Area start ===================== */}
    <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
             <h4> What is GST? </h4>
  <p> The Goods and Services Tax (GST) is a uniform indirect tax levied on goods and services across the country. GST, as an umbrella tax, will replace central taxes like central excise, service tax, additional duties of excise & customs, special additional duty of customs, besides cesses and surcharges, on supply of goods and services. </p>
  <h5> KEY FEATURES OF GST </h5>
  <ul className='single-list-inner style-check style-check mb-3'>
    <li><FaCheckCircle/> GST would be levied on ‘supply’ of goods and services and hence the present prevalent concepts of levy of excise on manufacture, VAT/CST on sales, entry tax on entry of goods in local area would no longer be relevant. The ambit of ‘supply’ is quite wide and covers supply of goods and services without consideration from one taxable person to another.  </li>
    <li><FaCheckCircle/> There would be dual GST i.e. both the Centre and the States would concurrently levy GST across the entire goods and services supply chain on a common base.
Centre would levy Central GST (CGST) and States would levy State GST (SGST) on every supply of goods and services within a State. Integrated GST (IGST) would be levied on all inter-state supplies by the Centre and then transferred to the Destination State. Unlike in the present scenario, IGST would have to be paid on all inter-state supplies, be it in the nature of a sale or stock transfer.  </li>
    <li><FaCheckCircle/> Present Central Taxes like Central Excise, Service Tax, CVD, SAD, CST and State Taxes like VAT, CST, Entry Tax, Luxury Tax would get subsumed under GST. Customs is outside GST and hence Basic Customs Duty would continue on imports.  </li>
    <li><FaCheckCircle/> GST is a destination based consumption tax, which essentially implies that the revenue will accrue to the State where the consumer resides. This is unlike the present origin based levy where the revenue accrues to the origin state from where the movement originates.  </li>
    <li><FaCheckCircle/> Seamless flow of credit would be there under GST whereby CGST would be allowed to be set-off against CGST and IGST, SGST against SGST and IGST and IGST against IGST, CGST and SGST in that order. However, CGST credit will not be allowed to be set-off against SGST and vice versa.  </li>
    <li><FaCheckCircle/> Liability for payment of GST would arise at the time of supply of goods and service. In terms of model law, receipt of advance payments for supply of goods and/or services would be considered as ‘time of supply’ and tax liability would arise on such advance receipt.  </li>
    <li><FaCheckCircle/> Registration threshold has been presently kept at Rs. 20 Lakhs (Rs. 10 lakhs in case of North East States and Sikkim) in the draft model law. Existing registered assesses would be migrated into GST, first provisionally and then finally subject to furnishing of requite information. Assesses have the option to take business segment-wise registration.  </li>
    <li><FaCheckCircle/> Multiple Registrations: If any Dealer is supplying goods/ services in multiple states & is having multiple branches / place of supply, in those states, then each such dealer will have to obtain a GST registration in each such state. Whereas if a dealer is supplying goods and services from any one state to different parts of India, then that dealer need not obtain multiple GST registrations. Only one state’s GST registration will be sufficient for that dealer.  </li>
    <li><FaCheckCircle/> Option of composition levy is also prescribed, if aggregate turnover of a tax payer is Rs. 75 lakhs. Persons adopting composition levy would be neither entitled to charge GST from their customers nor will be eligible to avail credit of input tax. However, composition levy is not allowable to assessee who affects inter-State supplies. Under Service providers, the composition scheme is available only to Restaurants.  </li>
    <li><FaCheckCircle/> Under GST, every assessee would have to upload invoice level outward supply details for B2B transactions. Details of inward supplies and tax credit would be auto-populated based on sales details uploaded by the vendor. Hence, a robust IT infrastructure at the end of both supplier and recipient is critical for hassle free tax credits and avoid denial of credits due to mismatch issues.  </li>
    <li><FaCheckCircle/> Provisions relating to payment of tax under reverse charge, tax deductions at source are expected to continue under GST regime for specified persons/transactions. Thus, additional compliances would continue on the part of recipients, so far as tax payments under reverse charge and deduction at source are concerned.  </li>
    <li><FaCheckCircle/> Reverse Charge: If any payment is made for supply of goods/ services to an un-registered dealer, then the un-registered dealer is not authorized to charge/ collect any GST on that supply. But the supply recipient is supposed to deposit the due charge on the supply of gods/ services, by unregistered dealer. This is the mechanism of reverse charge.  </li>
    <li><FaCheckCircle/> Summary: We can also safely categorize all expenses of a registered dealer, into Salary & Non –salary Expenses. NO GST is applicable on salary payment. But all payments, other than salary payments, are covered by GST. On all expense payments, other than salaries, GST has to be paid. Either by the Registered provider who supplies goods/ services to you, or the Reverse charge which you pay on any un-registered dealer’s supply of gods/ services.  </li>
<li><FaCheckCircle/> Rates of Tax under GST: There are mainly 4 Rates prescribed under GST, i.e. 5%, 12%, 18% & 28%. Most of the goods are covered under 5% to 18%, whereas most of services are Taxed at the Rate of 12% to 18%.
  </li>
    <li><FaCheckCircle/> Filings of Returns under GST : </li>

  </ul>



                        <h4 className='pb-5'> GST Return and Their Due Dates </h4>
  <table className='table table-hover border table-striped'>
  <thead>
    <tr>
      <th className='w-140'> RETURN FORM </th>
      <th> WHAT TO FILE? </th>
      <th> BY WHOM? </th>
      <th> BY WHEN? </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td> GSTR-1 </td>
      <td>Details of outward supplies of taxable goods and/or services effected </td>
      <td> Registered Taxable Supplier </td>
      <td> 	10th of the next month </td>
    </tr>
    <tr>
      <td> GSTR-2 </td>
      <td> Details of inward supplies of taxable goods and/or services effected claiming input tax credit. </td>
      <td> Registered Taxable Recipient </td>
      <td> 15th of the next month </td>
    </tr>
    <tr>
      <td> GSTR-3 </td>
      <td> Monthly return on the basis of finalization of details of outward supplies and inward supplies along with the payment of amount of tax. </td>
      <td> Registered Taxable Person </td>
      <td> 	20th of the next month </td>
    </tr>
    <tr>
      <td> GSTR-4 </td>
      <td> 	Quarterly return for compounding taxable person. </td>
      <td> Composition Supplier </td>
      <td> 	18th of the month succeeding quarter </td>
    </tr>

    <tr>
      <td> GSTR-5 </td>
      <td> 	Return for Non-Resident foreign taxable person </td>
      <td> Non-Resident Taxable Person </td>
      <td> 	20th of the next month </td>
    </tr>
    <tr>
      <td> GSTR-6 </td>
      <td> 	Return for Input Service Distributor </td>
      <td> Input Service Distributor </td>
      <td> 	13th of the next month </td>
    </tr>
    <tr>
      <td> GSTR-7 </td>
      <td> 	Return for authorities deducting tax at source. </td>
      <td> Tax Deductor </td>
      <td> 10th of the next month </td>
    </tr>

    <tr>
      <td> GSTR-8 </td>
      <td> 	Details of supplies effected through e-commerce operator and the amount of tax collected </td>
      <td> E-commerce Operator/Tax Collector </td>
      <td> 10th of the next month </td>
    </tr>
    <tr>
      <td> GSTR-9 </td>
      <td> 	Annual Return </td>
      <td> Registered Taxable Person </td>
      <td> 31st December of next financial year </td>
    </tr>

    <tr>
      <td> GSTR-10 </td>
      <td> Final Return	 </td>
      <td> Taxable person whose registration has been surrendered or cancelled. </td>
      <td> Within three months of the date of cancellation or date of cancellation order, whichever is later. </td>
    </tr>
    <tr>
      <td> GSTR-11 </td>
      <td> Details of inward supplies to be furnished by a person having UIN </td>
      <td> Person having UIN and claiming refund </td>
      <td> 28th of the month following the month for which statement is filed </td>
    </tr>
 
  </tbody>
</table>
   
   
      </div>
              </div>
            </div>
          </div>

    <FooterOne />
    
    </>
  )
}

export default GstReturnandTheirDueDates