import React from 'react'
import NavbarTwo from '../components/NavbarTwo';
import FooterOne from '../components/FooterOne';
import Breadcrumb from '../components/Breadcrumb';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const DscRegistration = () => {
  return (
  <>
  
  
<NavbarTwo />
        <Breadcrumb title={"DSC Registration"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
              <div className='col-lg-12 qna_section'>
                        <h4 className='pb-4'> DSC Registration </h4>
                      
<p> Time Period for Completion of DSC Registration: 2 to 4 days </p>
<h5> Steps for completion of DSC registration are as follows </h5>
<p> In order to incorporate a company online, all the respected documents must have DSC of the company's director. Thus the company director must have a Class-II Digital Signature Certificate. </p>
<p> Digital Signature Certificate (DSC) can be applied for by directly approach the Certifying Authorities (CAs) with original supporting documents and self-attested copies. </p>
<p> DSC can also be obtained, wherever offered by CA, using Aadhar eKYC based authentication. In these cases, no supporting documents are required. </p>
<ul>
    <li> A letter/certificate issued by a Bank, certified by the Bank Manager, containing the applicant's information as retained in the Bank database can be accepted. </li>
</ul>
<p> A DSC is valid for 1 or 2 years and can be renewed. </p>
<p> Ministry of Corporate Affairs has appointed six certifying authorities. DSC can be obtained through any of them including National Informatics Centre (NIC), IDRBT, Safe Script, nCode, Tata Consultancy Services, NC-Code, and e-Mudhra. All of these authorities have different rates, though. </p>
<p> The cost of the USB token used to store the certificate would be included in the charges. </p>
<p> Hard copies of the Forms and documents will be couriered to the vendor (online legal service companies, including Simplitax, would, however, need only self-attested soft copies to begin the process).This can take approximately two to five days. </p>
<p> Documents to be submitted: </p>
<p> Hard copy of the completed Class-II form </p>
<p> Identity Proof: Copy of the PAN card or, in case of a foreign national, copy of the Passport </p>
<p> Address Proof: Copy of (anyone) Passport/ Voter ID/Election/ Ration Card/ Utilities Bill /Driver's License/ AADHAAR Card. In the case of the Utilities Bill (Gas/Telephone), it should be recent, not older than 2 months in the case of an Indian and not older than 12 months in the case of a foreign national and it must be in the name of the Applicant. </p>
<p> Common Reasons for Rejection of DSC Registration </p>

<p className='pt-4'> The following minor mistakes may cause a rejection of your DSC Registration: </p>
 <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Abbreviating names: Never abbreviate the directors' names, regardless of what is mentioned in the ID/Address proof. </li>
        <li> <FaCheckCircle /> Spelling mistakes: There must be no spelling errors in the mentioned names. </li>
        <li> <FaCheckCircle /> Old bills: Bills shall not be older than 2 months and must be in the name of the applicant. </li>
        <li> <FaCheckCircle /> Prefixes: Avoid prefix such as Mr. /Mrs. /Shri. etc. </li> 
                                  </ul>
 <p> These are stepwise procedure to get DSC registration. Simplitax is your online CA/CS companion which can help you with DSC Registration in India. </p>


                           </div>

                     {/* CTA START */}

<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> Looking for best professional services at competitive price? </h5>
                </div>
             
   <div> <Link to='/contact' className='btn btn-primary'> Enquire Now </Link> </div>
              </div>
  </div>

  {/* CTA END */}

               </div>
            </div>
          </div>

 <FooterOne/>

  </>
  )
}

export default DscRegistration