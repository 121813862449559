import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBarTwo from "../components/NavbarTwo";
import OurVisions from "../components/OurVision";

const OurVision = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={"Our Vision"} />

     <OurVisions/>

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default OurVision ;
