import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle,  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const LlpRegistration = () => {
  return (
   <>

<NavbarTwo/>
        <Breadcrumb title={"LLP Registration"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> LLP Registration </h4>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> LLP Registration is most popular form of Business entity for professionals </li>
        <li> <FaCheckCircle /> Flexibility of addition and deletion of partners </li>
        <li> <FaCheckCircle /> Lower incorporation cost as compared to incorporating a Pvt. Ltd. Co. </li>
        <li> <FaCheckCircle /> Lower annual ROC filing cost as compared to a Pvt. Ltd. Co. </li>
        <li> <FaCheckCircle /> No dividend distribution tax </li>
        <li> <FaCheckCircle /> No minimum alternate tax </li>
        <li> <FaCheckCircle /> No Audit required if Turnover is less than Rs 40 lakhs p.a. and capital is less than Rs 25 lakhs. </li>
        <li> <FaCheckCircle /> Liability of Partners is limited as compared to traditional partnership firm </li> 
                                  </ul>
                           </div>
                    <div className='col-lg-12 col-12 text-center'>
                      <h4 className='my-5'> Inclusions in LLP Registration Package </h4>
                    </div>
                    <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' /> Digital Signatures Certificates (DSC) of 2 Partners </p></div>
                     <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Director Identification Nos. (DIN) of 2 Partners </p></div>
                       <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Company name approval certificate </p></div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Incorporation certificate </p> </div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  LLP Agreement </p> </div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  PAN & TAN Registration </p> </div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Company stamp </p> </div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Assistance in opening current account in Bank </p> </div>
                        
                       

                         <div className='col-lg-12 col-12 text-center'>
                      <h4 className='my-5'> Time Involved in the Process </h4>
                    </div>
                    <div className='col-lg-6 col-md-6'> <p> <FaCheckCircle className='blue-icon' />  Approx. 2 working days for drafting and execution of proprietorship </p></div>
                     <div className='col-lg-6 col-md-6'> <p> <FaCheckCircle className='blue-icon' />   Approx. 10 working days in getting PAN</p></div>



                     <div className='col-lg-12 col-12 my-5'>
                     <h4 className='text-center'> Document Required for Limited Liability Partnership (LLP) </h4>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Copy of PAN card of all Partners </li>
        <li> <FaCheckCircle /> Copy of Address proof of all Partners (Any one of Passport or Voter id or Aadhar card) </li>
        <li> <FaCheckCircle /> Proof of Business premises where LLP will be registered (It can be from home also) </li>
        <li> <FaCheckCircle /> In case of rented premises- Copy of rent agreement + Latest electricity bill in name of landlord + NOC. </li>
        <li> <FaCheckCircle /> In case of owned premises- Copy of sale deed + Latest electricity bill in name of owner + NOC. </li>
        <li> <FaCheckCircle /> 2 Colored passport size photograph of all Partners </li>
        <li> <FaCheckCircle /> Mobile no., email ids of all partners, proposed name/s of LLP, objects of LLP. </li>
                                  </ul>
                    </div>


                     {/* CTA START */}

<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> LLP Registration </h5>
                </div>
                <div>
                  <p> Starts from </p>
                  <h5><img src='../assets/img/inr.png' alt='Indian Rupee' className='img-fluid' /> 10,000/- </h5>
                  <p> All inclusive fees </p>
                </div>
   <div>
                  <Link to='/contact' className='btn btn-primary'> Enquire Now </Link>
                </div>
              </div>
  </div>

  {/* CTA END */}

               </div>
            </div>
          </div>

          <div
        className='faq-area  bg-cover pd-top-90 pd-bottom-110'
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className='container'>
          <div className='row'>
 <div  className='col-xl-12 col-lg-12'
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-4 mt-lg-0'>
              <h2 className='title text-center'> FAQS </h2>
              
              </div>
              <div
                className='accordion accordion-inner style-2 accordion-icon-left mt-3'
                id='accordionExample'
              >
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    > At what rate LLP is taxed in India?
                    </button>
                  </h2>
  <div id='collapseOne' className='accordion-collapse collapse show' aria-labelledby='headingOne'
        data-bs-parent='#accordionExample' >
                    <div className='accordion-body'> LLP is taxed @ 30 % on profit. No slab benefit available. </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >  What is difference between traditional partnership firm and LLP?
                    </button>
                  </h2>
<div id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <ul className='single-list-inner style-check style-check mb-3'>
                        <li><FaCheckCircle/> In traditional firm, liability of partners is unlimited. But In LLP, liability of partners is limited. </li>
                        <li><FaCheckCircle/> Any property may be purchased in name of LLP but not in case of traditional partnership. </li>
                        <li><FaCheckCircle/> Registration of LLP is compulsory whereas registration of partnership firm is optional. </li>
                      </ul>
  
                    </div>
                  </div>
                </div>



                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseThree' aria-expanded='false'
                      aria-controls='collapseThree'
                    > How many partners are required for starting LLP? </button>
                  </h2>
                  <div id='collapseThree'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingThree'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Minimum 2 Designated Partners are required to form an LLP. They can be even family members or relatives or friends. But partners should not be minors.
                    </div>
                  </div>
                </div>

   <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFour'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingFour' aria-expanded='false'
                      aria-controls='collapseheadingFour'
                    > What are the requirements relating to designated partners in LLP?
                    </button>
                  </h2>
                  <div id='collapseheadingFour' className='accordion-collapse collapse'
                    aria-labelledby='headingheadingFour'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
<p> Every LLP should have at least 2 designated partners. Both should be individuals. One Designated partner can be foreigner but not both. In case, LLP has 2 body corporates as partners then in that case, either partners of LLP or nominee of body corporate can become designated partners. </p>
 </div>
                  </div>
                </div>

  <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFive'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingFive' aria-expanded='false'
      aria-controls='collapseheadingFive'
  >  Can a foreigner be designed partner in LLP? </button>
                  </h2>
    <div id='collapseheadingFive' className='accordion-collapse collapse' aria-labelledby='headingheadingFive'
           data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                      <p> Yes, provided the other designated partner is resident in India. </p>
  </div>
                  </div>
                </div>


                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingSix'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingSix' aria-expanded='false'
      aria-controls='collapseheadingSix'
  >  What is the minimum capital with which LLP can be started? </button>
                  </h2>
    <div id='collapseheadingSix' className='accordion-collapse collapse' aria-labelledby='headingheadingSix'
           data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                      <p> No limit. Can be started with even Rs 10,000 </p>
  </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingSeven'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingSeven' aria-expanded='false'
      aria-controls='collapseheadingSeven'
  >  What are the steps involved in LLP Registration? </button>
                  </h2>
    <div id='collapseheadingSeven' className='accordion-collapse collapse' aria-labelledby='headingheadingSeven'
           data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                      <p> Firstly, Apply for Digital signature of all Partners, Secondly apply for DIN of all Partners </p>
<p> Thirdly apply for name approval/reservation, then file MOA, AOA and apply for incorporation. Finally get the certificate of LLP Registration India. </p>
  </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingEight'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingEight' aria-expanded='false'
      aria-controls='collapseheadingEight'
  >  Once LLP is registered what other formalities are required before starting operations? </button>
                  </h2>
    <div id='collapseheadingEight' className='accordion-collapse collapse' aria-labelledby='headingheadingEight'
           data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                      <p> You need to apply for PAN and TAN no. of LLP, thereafter open current account in bank and deposit paid up share capital money </p>
  </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingNine'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingNine' aria-expanded='false'
      aria-controls='collapseheadingNine'
  >  What are the periodical compliances which need to be done by LLP? </button>
                  </h2>
    <div id='collapseheadingNine' className='accordion-collapse collapse' aria-labelledby='headingheadingNine'
           data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                      <p> Normally, LLP has to do following compliances: </p>
                    
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Accounting or Book keeping (On daily basis) </li>
        <li> <FaCheckCircle /> GST returns to be filed online monthly </li>
        <li> <FaCheckCircle /> TDS returns of Salary paid and vendor payment is to be filed quarterly, if applicable  </li>
        <li> <FaCheckCircle /> Tax audit has to be done by CA and audit report needs to be collected in case annual turnover is more than Rs. 1 crore in case of trading business or Rs 50 lakh in case of professional business. </li>
        <li> <FaCheckCircle /> Balance sheet and Profit and Loss account and other financials need to be prepared and maintained. </li>
        <li> <FaCheckCircle /> Income tax return of LLP needs to be filed online annually before 30th September </li>
        <li> <FaCheckCircle /> ROC returns (approx. 2 in nos.) needs to be filed annually </li>
        <li> <FaCheckCircle /> Other need based compliances </li> 
        <li> <FaCheckCircle /> Advance tax needs to be paid periodically </li> 
                                  </ul>

  </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTen'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingTen' aria-expanded='false'
      aria-controls='collapseheadingTen'
  >  Do I need to file returns and do other compliance even if there are no operations in the LLP? </button>
                  </h2>
    <div id='collapseheadingTen' className='accordion-collapse collapse' aria-labelledby='headingheadingTen'
           data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                      <p> Yes, you need to file NIL returns, prepare balance sheet and profit and loss account even in case of none or less transactions </p>
  </div>
                  </div>
                </div>








             </div>
            </div>
          </div>
        </div>
  </div>
 <FooterOne/>
   
   
   </>
  )
}

export default LlpRegistration