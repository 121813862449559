import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle, } from "react-icons/fa";

const PayrollOverview = () => {
  return (
  <>
     <NavbarTwo/>
        <Breadcrumb title={"Payroll Overview"} />
    
{/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> Payroll Processing </h4>
    <p> SimpliTax provides high quality & secure payroll services. </p>
    <p> For any company, it’s a major challenge to manage payroll function, which includes not just the adherence to various statutory compliances, but publishing of various payroll reports, including payslips for every employee, each month. </p>
    <p> We at SimpliTax feel proud to have served many organisations, all over India, who have benefited from our secure Network and the Expert handling of this routine, but large quantum of work, each month. </p>
    <p> <b> Our gamut of Payroll Processing services include : </b></p>
    <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Preparing Monthly Pay slips  </li>
        <li> <FaCheckCircle /> Preparing Monthly TDS calculation in each pay slip </li>
        <li> <FaCheckCircle /> Preparing Monthly PF & ESI contributions in each pay slip </li>
        <li> <FaCheckCircle /> Correctly displaying other deductions/ incentives/ bonuses, whenever disbursed </li>
        <li> <FaCheckCircle /> Correctly showing Monthly, as well Annual Tax calculations on each pay slip </li>
        <li> <FaCheckCircle /> Also provide functionality of Direct bank transfer to employee account with "paperless" payroll options </li>
                              
                            </ul>
    <p> Payroll processing involves tedious routine work. By Outsourcing this process your organization can focus on growing its main business. </p>
    <p> Save cost, focus on core tasks and enable growth without manpower and infrastructure restrictions. </p>
    <p> Contact Simplitax to outsource Payroll Processing. </p>


      </div>
              </div>
            </div>
          </div>
          {/* ===================== Service Details Area End ===================== */}
    
    
       
        <FooterOne/>
 
  
  </>
  )
}

export default PayrollOverview