import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle,  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const TrustRegistration = () => {
  return (
   <>

<NavbarTwo/>
        <Breadcrumb title={"Trust Registration"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12 qna_section'>
                        <h4 className='pb-4'> Trust Registration </h4>
<h5> How it is governed  </h5>
<p> Covered by Indian Trust Act, 1882 </p>
<h5> Comes under whose jurisdiction  </h5>
<p> Deputy Registrar/Charity commissioner of the relevant area </p>
<h5>  What is main document required </h5>
<p> Trust Deed </p>
<h5> Whether stamp duty is charged  </h5>
<p> Trust deed to be executed on non-judicial stamp paper, vary from state to state </p>
<h5> Minimum members required  </h5>
<p> 2 </p>
<h5> Managed by whom </h5>
<p> Trustees or Board of Trustees </p>
<h5> Legal title held by whom </h5>
<p> Trustees holds legal title of property </p>
<h5> Can it be dissolved </h5>
<p> Generally Not </p>
<h5> What if it becomes ineffective/dissolved </h5>
<p> If objects are not met, objects near to objects of trust will be added in deed </p>
<h5> Does annual return need to be filed </h5>
<p> Not required </p>
<h5> Online filing facility available? </h5>
<p> Not available </p>
<h5> Level of difficulty of compliance and time involved? </h5>
<p> Time consuming and Difficult </p>
<h5> Time taken for registration? </h5>
<p> 10-15 working days </p>
<h5> Cost involved </h5>
<p> Low </p>
<h5> Can 12A and 80 G be taken? </h5>
<p> Yes </p>
<h5> Whether preferred for GOVT. subsidy/grant? </h5>
<p> Low preference </p>
<h5> Whether prefered for FCRA regn </h5>
<p> Low preference </p>
<h5>  Transparency in work </h5>
<p> Less </p>
<h5> Difficulty in changing trustees or board  </h5>
<p> Easy </p>
<h5> Difficulty in changing registered office  </h5>
 <p> Difficult </p>
 <h5> Procedure of Registration of Trust </h5>
<p> Normally, trust is registered with the Registrar/sub-registrar office. Minimum 2 members are required for registering Trust. Both members can be family members. The settlor or founder of trust can also be managing trustee. Settler has to compulsorily to be present before registrar whereas no need for trustee for mandatorily present. </p>
 <h6 className='pt-4'><b> Documents required for Registration of Trust </b> </h6>
  <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Copy of trust deed in duplicate, one original one photocopy </li>
        <li> <FaCheckCircle /> 3 Photographs of settler and all trustee </li>
        <li> <FaCheckCircle /> Proof of ownership of property where business will be carried on </li>
        <li> <FaCheckCircle /> Declaration on Rs 10 stamp paper either by owner or by settler if he is owner that he is owner of property and no objection in using such property for purpose of trust. </li>
        <li> <FaCheckCircle /> Trust Deed has to be executed on non-judicial stamp paper. For trust deed- stamp duty is 3% of capital. For registration 2 witnessesare required with original ID and Address proof. </li>
                                  </ul>
   </div>
{/* CTA START */}

<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> Society Registration </h5>
                </div>
                <div>
                  <p> Starts from </p>
                  <h5><img src='../assets/img/inr.png' alt='Indian Rupee' className='img-fluid' /> 8,000/- </h5>
                  <p> All inclusive fees </p>
                </div>
   <div>
                  <Link to='/contact' className='btn btn-primary'> Enquire Now </Link>
                </div>
              </div>
  </div>

  {/* CTA END */}

               </div>
            </div>
          </div>

 <FooterOne/>     



   </>
  )
}

export default TrustRegistration