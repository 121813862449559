import React from "react";
import ContactAreaOne from "../components/ContactAreaOne";
import CounterAreaOne from "../components/CounterAreaOne";
import FooterOne from "../components/FooterOne";
import WorkProcessOne from "../components/WorkProcessOne";
import NavbarTwo from "../components/NavbarTwo";
import ContactAreaThree from "../components/ContactAreaThree";
import TestimonialTwo from "../components/TestimonialTwo";
import BrandAreaOne from "../components/BrandAreaOne";
import { Link } from "react-router-dom";
import BannerFive from "../components/BannerFive";

const HomeOne = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarTwo />

      <BannerFive />

      {/* Banner One */}
      {/* <BannerOne /> */}

  {/* Contact Area Three */}
    <ContactAreaThree />

     {/* Work Process One */}
     <WorkProcessOne />

      <TestimonialTwo />

  {/* Counter Area One */}
      <CounterAreaOne />

      {/* Contact Area One */}
      <ContactAreaOne />

      <div className="whatsapp">
<Link to='https://api.whatsapp.com/send?phone=919650264848&text=Interested' target='_blank'><img src='assets/img/whatsapp.png' alt='whatsapp chat' /></Link>
</div>

  {/* Brand Area One */}
  <BrandAreaOne />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default HomeOne;
