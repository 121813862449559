import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle,  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const EsiRegistration = () => {
  return (
    <>
    
  
    <NavbarTwo />
        <Breadcrumb title={"ESI Registration"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
              <div className='col-lg-12 qna_section'>
                        <h4 className='pb-4'> Employee State Insurance Corporation </h4>
<p> Employee State Insurance Corporation or ESIC is a self-financing social security and health insurance scheme which provides medical benefit, sickness benefit, maternity benefit, disablement benefit and various other benefits such as funeral expenses, free supply of physical aids etc. to the employees and their family. </p>
<p> Units or Establishments that have 10 or more employees, drawing the wages of up to Rs.15,000 a month are required to be registered for ESIC under the ESI Act 1948. The benefits provided by the scheme are funded from the contributions raised from covered employees and their employers at the fixed percentage of wages. At present, covered employees contribute 1.75% of the wages to the ESIC and covered employers contribute 4.75% of the wages, payable to their employees. The state government also contribute 1/8th share cost of medical benefit. ESI Registration is done through Simplitax. </p>
<p> All Establishments and Factories employing more than 10 employees are required to mandatorily apply for ESI registration within 15 days of the ESI Act, 1948 becoming applicable to them. ESI Registration can be done through Simplitax in Delhi NCR, Mumbai, Bengaluru, Chennai and other Indian cities. </p>
<h5> Choose ESI Registration because : </h5>
 <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Provides complete medical care to employees </li>
        <li> <FaCheckCircle /> Includes employee’s dependants also </li>
        <li> <FaCheckCircle /> Mandatory for units with Less then 10 employees </li>
        
                                  </ul>
<h5> Documents Required for ESI Registration </h5> 
<ul className='single-list-inner style-check style-check mb-3'>
<p> To be Submitted by Employer: </p>
<li> <FaCheckCircle /> Registration Certificate or Licence issued under Shops and Establishment Acts or Factories Act. </li>
<li> <FaCheckCircle /> Address Proof: Latest Rent receipt of the premises you are occupying indicating the capacity in which the premises are occupied, if applicable.  </li>
<li> <FaCheckCircle /> Latest building Tax/Property Tax receipt (Photocopy). </li>
<li> <FaCheckCircle /> Memorandum and Articles of Association/Partnership Deed/Trust Deed depending on the entity that is applying for registration. </li>
<li> <FaCheckCircle /> Photocopy of certificate of Commencement of production and/or Registration No. of CST/ST (or GST once it becomes applicable). </li>
<li> <FaCheckCircle /> Copy of PAN Card  </li>
<li> <FaCheckCircle /> Evidence in support of the date of commencement of production/business/first sale (e.g. Copy of First Invoice).  </li>
<li> <FaCheckCircle /> Month wise employment position, salary etc.  </li>
<li> <FaCheckCircle />Copy of bank statement </li>
<li> <FaCheckCircle /> To be Submitted by Employees: </li>
<li> <FaCheckCircle /> Family Photo in Duplicate </li>
</ul>
<h5> Advantages of ESI Registration </h5>
<ul className='single-list-inner style-check style-check mb-3'>
<li> <FaCheckCircle /> It provides complete medical benefits </li>
<li> <FaCheckCircle /> It includes dependants </li>
<li> <FaCheckCircle /> It can be used at different ESI dispensaries and hospitals </li>
<li> <FaCheckCircle /> Any payments made will be reimbursed </li>
<li> <FaCheckCircle/> It takes the needs of the disabled into account </li>
<li><FaCheckCircle /> Access to Medical care in ESI Dispensaries/Hospitals </li>
</ul>

<h5>Applicability of ESI Registration </h5>
<p> The following establishments employing 10 or more persons attracts ESI coverage: </p>
<ul className='single-list-inner style-check style-check mb-3'>
<li> <FaCheckCircle /> Shops </li>
<li> <FaCheckCircle /> Hotels or restaurants not having any manufacturing activity, but only providing service. </li>
<li> <FaCheckCircle /> Cinemas </li>
<li> <FaCheckCircle /> Roadside Motor Transport Establishments; </li>
<li> <FaCheckCircle/> News paper establishments.  </li>
<li> <FaCheckCircle /> Private Educational Institutions and Medical Institutions  </li>
<p> In some states the minimum employees required for coverage is for 20 or more. A few State Governments have not extended scheme to include Medical & Educational Institutions. </p>
</ul>
<h5> Concerns Related to ESI Registration </h5>
<p> The ESI scheme is a self-financing scheme from the contributions. The ESI funds are primarily built out of contribution from employers and employees monthly at a fixed percentage of wages paid. The State Governments also contributes 1/8th share of the cost of Medical Benefit. </p>
<p> 2. The following establishments employing 10 or more persons attracts ESI coverage:  </p>
<ol className='mb-3' type='a'>

    <li> Shops </li>
    <li> Hotels or restaurants not having any manufacturing activity, but only providing service. </li>
    <li> Cinemas </li>
    <li> Roadside Motor Transport Establishments </li>
    <li> News paper establishments. </li>
    <li> Private Educational Institutions and Medical Institutions </li>
</ol>
<p> In some states the minimum employees required for coverage is for 20 or more. A few State Governments have not extended scheme to include Medical & Educational Institutions. </p>
<p> Any sum deducted from wages under the ESI Act will be deemed to have been entrusted to the employer by the employee for the purpose of paying the contribution for which it was deducted. Non-payment or delayed payment of the Employee’s contribution after being deducted from the wages of the employee amounts to ‘ Breach of trust’ and is a criminal offence punishable under Section 406 of IPC. </p>
<h4> STEPS FOR ESIC REGISTRATION </h4>

<h5> Step 1 Getting ESI Registration: </h5>
<p> Establishmentsor Factoriesmust get themselves registered within 15 days of the Act becoming applicable to them by submitting an Employer’s Registration Form (Form-01) to the relevant Regional Office. </p>
<h5> Step 2 Obtaining Code Number: </h5>
<p> A 17-digit identification number called the Code number will be provided which must be used in all Correspondence relating to the Scheme. Form 3 (Return on Declaration) must also be submitted along with Form 1. </p>
<h5>  Step 3 For Employees: </h5>
<p> At the time of joining the insurable employment, employees are required to fill in aDeclaration Form (Form1) and submit two copies of a family photo to the employer, which must be submitted to the relevant ESI Branch Office by his employer. </p>
<h5> Step 4 Insurance Number:  </h5>
<p> The employee will then be allotted an insurance number for the purpose of his identification under the scheme. </p>
<h5> Step 5 Temporary Identity Card: </h5>
<p> The employees will also be issued a temporary identity card for availing medical benefit for him/herself and his/her family for a duration of three months. </p>
<h5> Step 6 Permanent Photo Identity Card: </h5>
<p> Thereafter, he will be provided with a permanent photo identity card. A person once registered need not register again when there is a change of employment. The same registration can be transferred from one employment to another. </p>



<h6><b> What is included in our Package </b> </h6>
<ul className='single-list-inner style-check style-check mb-3'>
<li> <FaCheckCircle/> ESI Consultation  </li>
<li> <FaCheckCircle/> Employer Registration </li>
<li> <FaCheckCircle/> Employee Registration </li>
<li> <FaCheckCircle/> Documentation </li>
</ul>
                           </div>

                     {/* CTA START */}

<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> Looking for best professional services at competitive price? </h5>
                </div>
              <div>
                  <Link to='/contact' className='btn btn-primary'> Enquire Now </Link>
                </div>
              </div>
  </div>

  {/* CTA END */}

               </div>
            </div>
          </div>

 <FooterOne/>

    


    
    </>
  )
}

export default EsiRegistration