import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle, } from "react-icons/fa";

const FilingItr4 = () => {
  return (
    <>
    
    <NavbarTwo/>
        <Breadcrumb title={"Filing ITR 4"} />
    
    
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> ITR-4 S (SUGAM) </h4>
    <p> The Sugam ITR-4S Form is the Income Tax Return form for those taxpayers who have opted for the presumptive income scheme as per Section 44AD and Section 44AE of the Income Tax Act. However, if the turnover of the business mentioned above exceeds Rs 2 crores, the tax payer will have to file ITR-3. </p>
    <h5> Presumptive Income & its Taxation – under section 44AD </h5>
    <p> When you are running a small business, you may not have enough resources to maintain proper accounting information and calculate your profit or loss. This makes it difficult to keep track of your income from such a business and find out how much tax you need to pay. </p>
    <p> With this in mind, the Income Tax Department has laid out some simple provisions where your income is presumptively calculated simply based on the gross receipts of your business. </p>


   <p> <b> Features of this Scheme </b> </p>
                 <ul className='single-list-inner style-check style-check mb-3'>
                            <li> <FaCheckCircle /> Your Net Income is estimated to be 8% of the gross receipts of your business. </li>
        <li> <FaCheckCircle /> But From FY 2016-17 onwards, if gross receipts are received through digital mode of payments, then Net Income is estimated at 6% of such gross receipts and for cash receipts, rate is still at 8% of such cash receipts. </li>
        <li> <FaCheckCircle /> You don’t have to maintain books of accounts of this business. </li>
        <li> <FaCheckCircle /> You have to pay 100% Advance Tax by 15th March for such a business. No need to comply with requirement of advance tax due dates of June, Sep, Dec. </li>                       
                            </ul>
                            <p> If you are running more than 1 business, the scheme has to be chosen for each business. </p>
                            <p> The scheme cannot be adopted by the taxpayer, if he has claimed deduction under section 10, 10A, 10B, Section 10BA, or Section 80HH to 80RRB in the relevant year. </p>
                            <p><b> Eligibility Criteria for this Scheme: </b> </p> 
                            <ul className='single-list-inner style-check style-check mb-3'>
                              <li> <FaCheckCircle /> Gross receipts or turnover of the business for which you want to avail this scheme should be less than Rs 2 crore. </li>
                              <li> <FaCheckCircle /> You must be a Resident in India. </li>
                              <li> <FaCheckCircle />  This scheme is allowed to an individual, a HUF or a partnership firm. It is not available to a Company. </li>
                              </ul>
                              <p> <b> Eligible Businesses: </b> The taxpayer may be in any business – retail trading or wholesale trading or civil construction or any other business to avail this scheme. But this method of income computation is NOT applicable to: </p> 
                              <ul className='single-list-inner style-check style-check mb-3'>
                              <li> <FaCheckCircle /> Income from commission or brokerage </li>
                              <li> <FaCheckCircle /> Agency business </li>
                              <li> <FaCheckCircle /> Business of plying, hiring or leasing goods carriage (Section 44AE is applicable) </li>
                              <li> <FaCheckCircle /> Professionals – who are carrying on the profession of legal, medical, engineering, architectural, accountancy, technical consultancy, interior decoration, an authorised representative, film artist, company secretary and information technology. Authorised representative means – any person, who represents someone, for a fee or remuneration, before any Tribunal or authority under the law. Film Artist includes a producer, actor, cameraman, director, music director, art director, dance director, editor, singer, lyricist, story writer, screenplay writer, dialogue writer, dress designer – basically any person who is involved in his professional capacity in the production of a film.(see Sec 44ADA) </li>
                                 </ul>
                                 <p> <b> Deduction for Business Expenses: </b> No business expenses are allowed to be deducted from the net income. Depreciation is also not deductible. However, in case of a partnership firm, the separate deduction for remuneration of partners and interest paid to partners is allowed. This must be within the limit specified under section 40(b). </p>
                                 <p> Even though depreciation is not allowed as a deduction is written down value (WDV) of the assets shall be considered as if depreciation has been allowed. </p>
                                <p> <b> Computing Turnover or Gross Receipts: </b> Gross receipts or Turnover mean the total collections of the business. The receipts shall be inclusive of VAT & Excise Duty. The receipts shall also include delivery charges as well as receipts from the sale of scrap. </p>
                                <p> Discounts given, advances received and money received on sale of assets should be excluded. </p>
                                <h5> Presumptive Income in case of taxpayers engaged in business of plying, leasing or hiring of trucks (under Section 44AE) </h5>
                                <p> For those who are in the business of plying, leasing or hiring of trucks; a scheme similar to presumptive income scheme under section 44D is available. </p>
                                <p> <b> Features of this scheme </b></p>
                                <ul className='single-list-inner style-check style-check mb-3'>
                                  <li><FaCheckCircle /> Net Income from a heavy goods vehicle (including any goods carriage) will be assumed as Rs 7,500 per month for each vehicle beginning assessment year 2015-16.  </li>
                                  <li><FaCheckCircle />  You don’t have to maintain books of accounts of this business. </li>
                                  <li><FaCheckCircle /> You have to pay 100% Advance Tax by 15th March for such a business. No need to comply with requirement of quarterly instalments due dates (June, sep, Dec) of advance tax.  </li>
                                  <li><FaCheckCircle />  You are not allowed to deduct any business expenses against the income. </li>
                                </ul>
                                <p> Here ‘Goods carriage’ means any vehicle used only for the carriage of goods. ‘Heavy goods vehicle’ means a goods carriage whose standalone weight (without loading goods) is more than 12,000 kgs. </p>
                                <p> Part of a month shall be rounded off to the next month. For example, if a goods carriage is owned for 7 months and 3 days, the net income shall be calculated as if the carriage was owned for 8 months. </p>
                                <p><b> Eligibility Criteria :</b>  To avail this scheme </p>
                                <ul className='single-list-inner style-check style-check mb-3'>
                                  <li> <FaCheckCircle /> You should be in the business of leasing or hiring trucks. </li>
                                  <li> <FaCheckCircle /> You should not own more than 10 goods carriages at any time during the year. Include carriages taken on hire purchase or on instalments. </li>
                                  <li> <FaCheckCircle /> You may be an individual, HUF, Company or partnership firm – scheme is allowed to all taxpayers. </li>
                                   </ul>
                                   <p><b>  Deduction for Business Expenses: </b> No business expenses are allowed to be deducted from the net income. Depreciation is also not deductible. However, in case of a partnership firm, separate deduction for remuneration of partners and interest paid to partners is allowed. This must be within the limit specified under section 40(b). </p>
                                   <p> Even though depreciation is not allowed as a deduction written down value (WDV) of the assets shall be considered as if depreciation has been allowed. </p>
                                   <h5> Presumptive Income in case of Professionals (under Section 44ADA) </h5>
                                   <p> The benefit of Presumptive tax rates were only available to Businesses. But now this benefit has been extended to professionals also .It will be applicable to the Professionals whose total gross receipts<b> does not exceed Rs 50 lakhs </b> in a financial year. </p>
                                   <p><b>  Presumptive Tax Rate: </b> The income of the professionals opting for this scheme would be assumed at 50% of the total Gross receipts for the year. </p>
                                   <p><b>  Applicability of the scheme: </b> The Persons engaged in the following profession can opt for this presumptive Income scheme: </p>
                                   <ul className='single-list-inner style-check style-check mb-3'>
                                    <li> <FaCheckCircle /> Medical </li>
                                    <li> <FaCheckCircle /> Engineering </li>
                                    <li> <FaCheckCircle /> Legal </li>
                                    <li> <FaCheckCircle /> Architectural Profession </li>
                                    <li> <FaCheckCircle /> Accountancy Profession </li>
                                    <li> <FaCheckCircle /> Technical Consultancy </li>
                                    <li> <FaCheckCircle /> Interior Decoration </li>
                                    <li> <FaCheckCircle /> Other Notified Professionals </li>
                                   </ul>
                </div>
              </div>
            </div>
          </div>
          {/* ===================== Service Details Area End ===================== */}
    
    
       
        <FooterOne/>
        </>
  )
}

export default FilingItr4