import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle,  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const SocietyRegistration = () => {
  return (
   <>
   
<NavbarTwo/>
        <Breadcrumb title={"Society Registration"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12 qna_section'>
                        <h4 className='pb-4'> Society Registration </h4>
<h5> How it is governed  </h5>
<p> Societies Registration Act 1860, which is an all-India Act </p>
<h5> Comes under whose jurisdiction  </h5>
<p> Registrar of societies of the particular State </p>
<h5>  What is main document required </h5>
<p> MOA and Rules and Regulations </p>
<h5> Whether stamp duty is charged  </h5>
<p> No stamp duty required for MOA and Rules and Regulations </p>
<h5> Minimum members required  </h5>
<p> 7 for local and 8 for national level </p>
<h5> Managed by whom </h5>
<p> Governing Council or Managing Committee </p>
<h5> Legal title held by whom </h5>
<p> Properties held in the name of Society </p>
<h5> Can it be dissolved </h5>
<p> Can be dissolved with an approval of 3/5th members or as per terms of MOA. </p>
<h5> What if it becomes ineffective/dissolved </h5>
<p> On Dissolution, funds and property not transferred to members of Society, but to some other similar society </p>
<h5> Does annual return need to be filed </h5>
<p> Required to be filed with Registrar </p>
<h5> Online filing facility available? </h5>
<p> Not available </p>
<h5> Level of difficulty of compliance and time involved? </h5>
<p> Time consuming and Difficult </p>
<h5> Time taken for registration? </h5>
<p> 10-15 working days </p>
<h5> Cost involved </h5>
<p> Medium </p>
<h5> Can 12A and 80 G be taken? </h5>
<p> Yes </p>
<h5> Whether preferred for GOVT. subsidy/grant? </h5>
<p> Low preference </p>
<h5> Whether prefered for FCRA regn </h5>
<p> Low preference </p>
<h5>  Transparency in work </h5>
<p> Less </p>
<h5> Difficulty in changing trustees or board  </h5>
<p> Complex </p>
<h5> Difficulty in changing registered office  </h5>
 <p> Difficult </p>

 <h6 className='pt-4'><b> For registration of Society, following steps are involved: </b> </h6>
  <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Name Search </li>
        <li> <FaCheckCircle /> You need to provide 3-4 proposed names of Society and we would get the name searched done and only names available for registration will be eligible.</li>
        <li> <FaCheckCircle /> Drafting of Memorandum and Rules of Society </li>
        <li> <FaCheckCircle /> Preparation of Application, getting it signed by all members and submission with government authorit </li>
        <li> <FaCheckCircle /> Getting the registered certificate. </li>
                                  </ul>
<h6><b> Documents Required for Registration of Society </b> </h6>
<ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Copy of PAN card of all 7/8 members </li>
        <li> <FaCheckCircle /> Copy of Address proof of all 7/8 members [ Any one of Voter id or Aadhar or Driving license or passport] </li>
        <li> <FaCheckCircle /> Passport size photograph of all members </li>
        <li> <FaCheckCircle /> You need to provide names of persons who would be PRESIDENT, TREASURER AND GENERAL SECRETARY, remaining 5 will be members of Governing Body </li>
       </ul>
                           </div>
                           
                                     
  {/* CTA START */}

<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> Society Registration </h5>
                </div>
                <div>
                  <p> Starts from </p>
                  <h5><img src='../assets/img/inr.png' alt='Indian Rupee' className='img-fluid' /> 8,000/- </h5>
                  <p> All inclusive fees </p>
                </div>
   <div>
                  <Link to='/contact' className='btn btn-primary'> Enquire Now </Link>
                </div>
              </div>
  </div>

  {/* CTA END */}

               </div>
            </div>
          </div>

 <FooterOne/>     
  </>
  )
}

export default SocietyRegistration