import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle,  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Section8Company = () => {
  return (
   <>
   
<NavbarTwo/>
        <Breadcrumb title={"Section 8 Company"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12 qna_section'>
                        <h4> Section 8 Company </h4>
<h5> How it is governed  </h5>
<p> It's governed by Indian Companies Act, 2013. </p>
<h5> Comes under whose jurisdiction  </h5>
<p> Regional Director & Registrar of Companies of concerned state. </p>
<h5>  What is main document required </h5>
<p> MOA & AOA </p>
<h5> Whether stamp duty is charged  </h5>
<p> No Stamp Duty Required </p>
<h5> Minimum members required  </h5>
<p> 2 </p>
<h5> Managed by whom </h5>
<p> Board of Directors </p>
<h5> Legal title held by whom </h5>
<p> Properties held in the name of Society </p>
<h5> can it be dissolved </h5>
<p> Yes, it can be dissolved </p>
<h5> What if it becomes ineffective/dissolved </h5>
<p> On Dissolution, funds, and property not transferred to members of Co. but to some other similar section 8 Co. </p>
<h5> Does annual return need to be filed </h5>
<p> Required to be filed with ROC </p>
<h5> Online filing facility available? </h5>
<p> Available </p>
<h5> Level of difficulty of compliance and time involved? </h5>
<p> Easy, Time saving and transparent procedure </p>
<h5> Time taken for registration? </h5>
<p> 20-30 working days </p>
<h5> Cost involved </h5>
<p> High </p>
<h5> Can 12A and 80 G be taken? </h5>
<p> Yes </p>
<h5> Whether preferred for GOVT. subsidy/grant? </h5>
<p> High preference </p>
<h5> Whether prefered for FCRA regn </h5>
<p> High preference </p>
<h5>  Transparency in work </h5>
<p> Very High because everything is online </p>
<h5> Difficulty in changing trustees or board  </h5>
<p> Easy </p>
<h5> Difficulty in changing registered office  </h5>
 <p> Easy </p>

 <h6 className='pt-4'><b> Procedures for Section 8 Company </b> </h6>
 <p> Section 8 Company is a Company licensed under Section 8 of the Companies Act, 2013 (the Act), erstwhile known as Section 25 Company under the Companies Act, 1956, which has main object; </p>
  <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> For promoting commerce, art, science, sports, education, research, social welfare, religion, charity, protection of environment or any such other object, </li>
        <li> <FaCheckCircle /> provided the profits, if any, or other income is applied for promoting only the objects of the company and </li>
        <li> <FaCheckCircle /> No dividend is paid to its members. </li>
                                  </ul>
<p> Therefore, Section 8 Company is a company registered for charitable or not-for-profit purposes. </p>
<p> Section 8 Company has various advantages when compared to Trust or Society. Section 8 company also has higher credibility amongst donors, Government departments and other stakeholders. </p>
<p> Further, the key feature of a Section 8 Company is that name of the Company can be incorporated without using the word “Limited” or “Private Limited” as the case may be. </p>
<p> (Note: Requirement of minimum capital under the Act shall not apply to Section 8 Company vide Notification of June 5, 2015). </p>
<h6><b> Procedure for Registration of Section 8 Company: </b> </h6>
<ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Minimum two people required for registration of Section 8 Company. </li>
        <li> <FaCheckCircle /> Obtaining Digital Signature Certificate (DSC) for proposed Directors not having Directors Identification Number (DIN) </li>
        <li> <FaCheckCircle /> Obtaining DIN from Ministry of Corporate Affairs by filing Form DIR-3, if not having DIN. </li>
        <li> <FaCheckCircle /> Filing of Form INC-1 for Reservation of Name. </li>
        <li> <FaCheckCircle /> Post approval of name from the concerned Registrar of Companies, file Form INC-12 [pursuant to Section 8 (1) of the Act and Rule 19 of Company (Incorporation) Rules, 2014] (the Rules) </li>

                                  </ul>

                                  <h6><b> Attachments to Form SPICe: </b> </h6>
<ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Draft Memorandum of Association of the proposed company in Form SPICe  </li>
        <li> <FaCheckCircle /> Draft Articles of Association of the proposed company </li>
        <li> <FaCheckCircle /> Declaration by Practicing Chartered Accountant / Practicing Company Secretary / Practicing Cost Accountant in Form SPICe MOA </li>
        <li> <FaCheckCircle /> Declaration from each person making application in Form SPICe AOA </li>
        <li> <FaCheckCircle /> Estimated Income and Expenditure for next 3 years.  </li>
        <li><FaCheckCircle /> (Note: Form SPICe is in physical mode and the e-form is not yet available on MCA21 Portal. Thus, the same needs to be filed in Form SPICe MOA with prescribed fees).  </li>
      </ul>

      <h6><b> Attachments to Form SPICe MOA: </b> </h6>
<ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Form INC-12 </li>
        <li> <FaCheckCircle /> Draft Memorandum of Association of the proposed company in Form SPICe </li>
        <li> <FaCheckCircle /> Draft Articles of Association of the proposed company </li>
        <li> <FaCheckCircle /> Declaration by Practicing Chartered Accountant / Practicing Company Secretary / Practicing Cost Accountant in Form SPICe MOA </li>
        <li> <FaCheckCircle /> Declaration from each person making application in Form SPICe AOA </li>
        <li> <FaCheckCircle /> Estimated Income and Expenditure for next 3 years </li>
        <li> <FaCheckCircle /> The Central Government after examining grants the license in Form SPICe </li>
        <li> <FaCheckCircle /> After obtaining the license, following forms need to be submitted with prescribed attachments </li>
        <li> <FaCheckCircle /> Form SPICe </li>
        <li> <FaCheckCircle /> Form SPICe MOA </li>
        <li> <FaCheckCircle /> Form SPICe AOA </li>
        <li> <FaCheckCircle /> Post Scrutiny of the submitted forms and documents the Corporate Identification Number (CIN) will be issued by the concerned Registrar of Companies. </li>
      </ul>


      <h6><b> List of documents required: </b> </h6>
<ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Identity Proof: Copy of Permanent Account Number (PAN) of all Directors/Promoters (Mandatory) </li>
        <li> <FaCheckCircle /> Address Proof: Copy of valid Passport/Driving Licence/Aadhar/Telephone Bill/Electricity Bill (not older than 2 months) </li>
        <li> <FaCheckCircle /> Latest passport size photographs of all Directors/Promoters </li>
        <li> <FaCheckCircle /> Rent Agreement or Leave &Licence Agreement, if registered office premise is taken on rent </li>
        <li> <FaCheckCircle /> Utility Bills of proposed registered office </li>
        <li> <FaCheckCircle /> Consent to act as Director in Form SPICe </li>
        <li> <FaCheckCircle /> Directors Directorship details in other Companies/LLPs, if any. </li>
      </ul>




                           </div>
                                     
  {/* CTA START */}

<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> Section 8 Company Registration </h5>
                </div>
                <div>
                  <p> Starts from </p>
                  <h5><img src='../assets/img/inr.png' alt='Indian Rupee' className='img-fluid' /> 20,000/- </h5>
                  <p> All inclusive fees </p>
                </div>
   <div>
                  <Link to='/contact' className='btn btn-primary'> Enquire Now </Link>
                </div>
              </div>
  </div>

  {/* CTA END */}

               </div>
            </div>
          </div>


 <FooterOne/>  

   </>
  )
}

export default Section8Company