import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle,  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const ProprietorshipRegistration = () => {
  return (
  <>
  <NavbarTwo/>
        <Breadcrumb title={"Proprietorship Firm Registration"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> Proprietorship Firm Registration </h4>
                     <ul className='single-list-inner style-check style-check mb-3'>
                            <li> <FaCheckCircle /> Easy to form a proprietorship firm </li>
        <li> <FaCheckCircle /> Any name may be chosen </li>
        <li> <FaCheckCircle /> Ideal for small business in unorganized sector </li>
        <li> <FaCheckCircle /> Less statutory compliances as compared to other forms of business ownership
 </li>
                                  </ul>
                           </div>
                    <div className='col-lg-12 col-12 text-center'>
                      <h4 className='my-5'> Inclusions in this package </h4>
                    </div>
                    <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' /> Sole proprietorship executed on non-judicial stamp paper and notarized </p></div>
                     <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  PAN / TAN Registration </p></div>
                       <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Firm’s Stamp </p></div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Assistance in opening current account in Bank </p> </div>
                         <div className='col-lg-12 col-12 text-center'>
                      <h4 className='my-5'> TIME INVOLVED IN THE PROCESS </h4>
                    </div>
                    <div className='col-lg-6 col-md-6'> <p> <FaCheckCircle className='blue-icon' />  Approx. 2 working days for drafting and execution of proprietorship </p></div>
                     <div className='col-lg-6 col-md-6'> <p> <FaCheckCircle className='blue-icon' />   Approx. 10 working days in getting PAN</p></div>

{/* CTA START */}

<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> Proprietorship Registration </h5>
                </div>
                <div>
                  <p> Starts from </p>
                  <h5><img src='../assets/img/inr.png' alt='Indian Rupee' className='img-fluid' /> 2000/- </h5>
                  <p> All inclusive fees </p>
                </div>
   <div>
                  <Link to='/contact' className='btn btn-primary'> Enquire Now </Link>
                </div>
              </div>
  </div>

  {/* CTA END */}


               </div>
            </div>
          </div>

          <div
        className='faq-area  bg-cover pd-top-90 pd-bottom-110'
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className='container'>
          <div className='row'>
 <div  className='col-xl-12 col-lg-12'
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-4 mt-lg-0'>
              <h2 className='title text-center'> FAQS </h2>
              
              </div>
              <div
                className='accordion accordion-inner style-2 accordion-icon-left mt-3'
                id='accordionExample'
              >
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    > At what rate profit from Proprietorship is taxed in India?
                    </button>
                  </h2>
  <div id='collapseOne' className='accordion-collapse collapse show' aria-labelledby='headingOne'
        data-bs-parent='#accordionExample' >
                    <div className='accordion-body'>
                    Proprietorship firms are taxed as per Individual’s taxation slabs </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                  How many Persons are required for starting a Proprietorship Firm?
                    </button>
                  </h2>
<div id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'> Only 1 person is required
                    </div>
                  </div>
                </div>



                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseThree' aria-expanded='false'
                      aria-controls='collapseThree'
                    > What is the minimum capital with which a proprietorship firm can be started?
                    </button>
                  </h2>
                  <div
                    id='collapseThree'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingThree'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                     No Limit
                    </div>
                  </div>
                </div>



                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFour'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingFour' aria-expanded='false'
                      aria-controls='collapseheadingFour'
                    > What are the Statutory Compliances that Proprietorship Firm and Proprietor has to comply?
                    </button>
                  </h2>
                  <div
                    id='collapseheadingFour'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingheadingFour'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <div className='row'>
                        <div className='col-lg-12 col-md-12 col-12'>
                        <h5> With Income Tax Department: </h5>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Proprietor has to file his Income tax returns showing the correct working of proprietorship business </li>
        <li> <FaCheckCircle /> Advance tax needs to be paid periodically </li>
        <li> <FaCheckCircle /> Tax audit has to be got done by CA and audit report needs to be collected in case annual turnover is more than Rs 1 crore in case of trading business or Rs 50 lakh in case of professional business. </li>
                                  </ul>
                                  <h5> Others </h5>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> GST returns to be filed online monthly </li>
        <li> <FaCheckCircle /> TDS returns of Salaries paid and vendor payments are to be filed quarterly, if applicable </li>
        <li> <FaCheckCircle /> Other need based compliances </li>
                                  </ul>

                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>

  <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFive'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingFive' aria-expanded='false'
      aria-controls='collapseheadingFive'
  > Do I need to file returns and do other compliance even if there are no operations in the Proprietorship Firm?
                    </button>
                  </h2>
                  <div
                    id='collapseheadingFive'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingheadingFive'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <p>  Every proprietor has to file his Individual ITR if his gross income, including proprietorship business is above minimum taxable limits. And for the proprietorship business one has to prepare balance sheet and profit and loss account, even if there is no transaction or there is loss. </p>
  </div>
                  </div>
                </div>






              </div>
            </div>
          </div>
        </div>


       
      </div>


          
      
      <FooterOne/>
  </>
  )
}

export default ProprietorshipRegistration