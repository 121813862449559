import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaEnvelope, FaPhoneAlt, } from "react-icons/fa";

const NavbarTwo = () => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
    console.log("hell");
  };

  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <>
  <div className="middlehead mob-hidden">
  <div className='container'>
  <div className="d-flex justify-content-between "> 
  <div>
<Link to='/'  className='logotop'>
              <img src='assets/img/logo.png' className='logotop' alt='img' />
            </Link>
            </div>
            <div >
<ul>
  <li>  <FaPhoneAlt /> +91 9650264848  </li>
  <li> <FaEnvelope /> info@simplitax.com  </li>
</ul>
            </div>
 </div>
</div>
</div>

      <nav className='navbar navbar-area navbar-area-2 navbar-expand-lg bg-white'>
        <div className='container nav-container'>
       <div className='responsive-mobile-menu'>
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target='#itech_main_menu'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
          </div>

          <div className='logo desk-hidden'>
            <Link to='/'>
              <img src='assets/img/logo.png' alt='img' />
            </Link>
          </div> 
<div  className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id='itech_main_menu'
          >
            <ul className='navbar-nav menu-open text-lg-start'>
            
              <li className='menu-item-has-children'>
                <Link to='/'> About us </Link>
                <ul className='sub-menu'>
                  <li>
                    <Link to='/our-vision'> Our Vision </Link>
                  </li>
                  <li>
                    <Link to='/service-details'> Executive Team </Link>
                  </li>
                </ul>
              </li>
              <li className='menu-item-has-children'>
                <Link to='filing-itr-1'> ITR Filing </Link>
                <ul className='sub-menu'>
      <li> <Link to='/service'> Type of ITR & Their Due Dates  </Link>  </li>
          <li> <Link to='/filing-itr-1'> Filing ITR 1 </Link>  </li>
                  <li> <Link to='/filing-itr-2'> Filing ITR 2 </Link> </li>
    <li> <Link to='/filing-itr-3'> Filing ITR 3 </Link>  </li>
    <li> <Link to='/filing-itr-4'> Filing ITR 4 </Link> </li>
  <li> <Link to='/filing-itr-5'> Filing ITR 5 </Link> </li>
 <li> <Link to='/filing-itr-6'> Filing ITR 6 </Link> </li>
  <li> <Link to='/filing-itr-7'> Filing ITR 7 </Link> </li>
                </ul>
              </li>

    <li className='menu-item-has-children'>
                <Link to='/'> GSTR Filing </Link>
                <ul className='sub-menu'>
  <li> <Link to='/gst-return-and-their-due-dates'> Type of GST Returns & Their Due Dates   </Link> </li>
  <li>  <Link to='/filing-gstr1'> Filing GSTR 1 </Link> </li>
  <li> <Link to='/service-details'> Filing IIF </Link>  </li>
<li> <Link to='/service-details'> Checking GSTR 2/2A </Link> </li>
 <li> <Link to='/service-details'> Filing GSTR 3B </Link> </li>
  <li>  <Link to='/service-details'> Filing CMP-8/GSTR-4 </Link> </li>
                </ul>
              </li>

              <li className='menu-item-has-children'>
                <Link to='/'> Payroll </Link>
                <ul className='sub-menu'>
                  <li> <Link to='/payroll-overview'> Overview   </Link>  </li>
                  <li>  <Link to='/'> Basic Payroll Plan </Link> </li>
                  <li> <Link to='/service-details'> Advance Payroll Plan </Link> </li>
<li> <Link to='/service-details'> Premium Payroll Plan </Link>  </li>
                </ul>
              </li>

    <li className='menu-item-has-children'>
                <Link to='/'> Book Keeping </Link>
                <ul className='sub-menu'>
                  <li>
                    <Link to='/'> Overview   </Link>
                  </li>
                  <li>  <Link to='/'> Basic Payroll Plan </Link> </li>
                  <li>
                    <Link to='/service-details'> Advance Payroll Plan </Link>
                  </li>
                  <li>
                    <Link to='/service-details'> Premium Payroll Plan </Link>
                  </li>
                </ul>
              </li>


              
              <li className='menu-item-has-children'>
                <Link to='/'> Registrations </Link>
                <ul className='sub-menu megamenu'>
<div className='row'>
  <div className='col-xl-4 col-lg-4 col-12'>
   <h6 className='pt-4 ps-3'> Starting A New Business  </h6>
   <li> <Link to='/proprirtorship-registration'> Proprietorship    </Link> </li>
                  <li>  <Link to='/partnership-firm-registration'> Partnership </Link> </li>
  <li> <Link to='/LLP-registration'> Limited Liability Company </Link> </li>
<li> <Link to='/one-person-company-registration'> One Person Company </Link> </li>
  <li> <Link to='/private-limited-company-registration'> Private Limited Company </Link>  </li>
    <li> <Link to='/public-limited-company-registration'> Public Limited Company </Link> </li>
    <li> <Link to='/foreign-company-registration'> Foreign Company </Link> </li>
    <li> <Link to='/shop-and-establishment-registration'> Shop & Establishment Regn </Link> </li>

  </div>

  <div className='col-xl-4 col-lg-4 col-12'> 
  <h6 className='pt-4 ps-3'> Starting AN NGO </h6>
 
  <li> <Link to='/section-8-company'> Section 8 Company  </Link>  </li>
                  <li>  <Link to='/society-registration'> Society </Link> </li>
                  <li> <Link to='/trust-registration'> Trust </Link> </li>
                </div>
  <div className='col-xl-4 col-lg-4 col-12'> 
  <h6 className='pt-4 ps-3'> Statutory Registrations </h6> 
  <li> <Link to='/gst-registration'> GST Registration   </Link> </li>
  <li>  <Link to='/pf-registration'> PF Registration </Link> </li>
  <li> <Link to='/esi-registration'> ESI Registration </Link> </li>
<li> <Link to='/iec-registration'> IEC Code </Link> </li>
  <li> <Link to='/dsc-registration'> DSC ( Class ii & iii ) </Link>  </li>
  <li> <Link to='/service-details'> MSME Registration </Link>  </li>
  <li> <Link to='/service-details'> Startup India Registration </Link>  </li>
  <li> <Link to='/service-details'> GEM/Tender Registration </Link>  </li>
    </div>
</div>
  </ul>
      </li>

   <li className='menu-item-has-children'>
                <Link to='/'> Compliances </Link>
                <ul className='sub-menu megamenu'>
<div className='row'>
  <div className='col-xl-4 col-lg-4 col-12'>
   <h6 className='pt-4 ps-3'> MCA </h6>
   <li> <Link to='/'> Company/LLP Incorpation   </Link> </li>
                  <li>  <Link to='/'> Annual Filings of Company/LLP </Link> </li>
  <li> <Link to='/service-details'> Change Filings </Link> </li>
<li> <Link to='/service-details'> Making DSC  </Link> </li>
  <li> <Link to='/service-details'> DIN Allotment </Link>  </li>
  </div>

  <div className='col-xl-4 col-lg-4 col-12'> 
  <h6 className='pt-4 ps-3'> PF </h6>
 
  <li> <Link to='/'> Registration of Entity Under PF Laws  </Link>  </li>
                  <li>  <Link to='/'> Monthly Deposit of PF </Link> </li>
      <li> <Link to='/service-details'> Monthly Filing of PF Returns </Link> </li>
      <li> <Link to='/service-details'> Managing Monthly & Annual PF Compliances </Link> </li>
                </div>

                <div className='col-xl-4 col-lg-4 col-12'> 
  <h6 className='pt-4 ps-3'> ESI </h6>
 
  <li> <Link to='/'> Registration of Entity Under ESI Laws  </Link>  </li>
                  <li>  <Link to='/'> Monthly Deposit of ESI </Link> </li>
      <li> <Link to='/service-details'> Monthly Filing of ESI Returns </Link> </li>
      <li> <Link to='/service-details'> Managing Monthly & Annual ESI Compliances </Link> </li>
                </div>

</div>
  </ul>
              </li>

         <li className='menu-item-has-children'>
                <Link to='/'> Assurance </Link>
                <ul className='sub-menu'>
<li> <Link to='/'> Statutory Audit & Assurance  Services <br/> under Various Laws </Link> </li>
                  </ul>
              </li>

              <li className='menu-item-has-children'>
                <Link to='/'> Help Tools </Link>
                <ul className='sub-menu helptools'>
<div className='row'>

<div className='col-xl-3 col-lg-3 col-12'>
   <h6 className='pt-4 ps-3'> For Income Tax </h6>
   <li> <Link to='https://eportal.incometax.gov.in/iec/foservices/#/login'> E File Your ITR  </Link> </li>
                  <li>  <Link to='https://eportal.incometax.gov.in/iec/foservices/#/e-pay-tax-prelogin/user-details' target='_blank'> Pay Income Tax Online </Link> </li>
  <li> <Link to='https://eportal.incometax.gov.in/iec/foservices/#/pre-login/itrStatus'> Check ITR Status </Link> </li>
<li> <Link to='https://eportal.incometax.gov.in/iec/foservices/#/e-pay-tax-prelogin/user-details' target='_blank'> Pay TDS Online  </Link> </li>
  <li> <Link to='https://eportal.incometax.gov.in/iec/foservices/#/know-payment-status/payment-information' target='_blank'> Know Tax Payment Status </Link>  </li>
  <li> <Link to='https://tin.tin.nsdl.com/pantan/StatusTrack.html' target='_blank'> Know Your TAN </Link> </li>
<li> <Link to='https://tin.tin.nsdl.com/oltas/refund-status-pan.html' target='_blank'> Check Your Refund Status  </Link> </li>
  <li> <Link to='https://incometaxindia.gov.in/pages/tools/tax-calculator.aspx' target='_blank'> Tax Calculator </Link>  </li>
  <li> <Link to='https://eportal.incometax.gov.in/iec/foservices/#/pre-login/instant-e-pan'> Instant E-PAN </Link>  </li>
  </div>

  <div className='col-xl-3 col-lg-3 col-12'>
   <h6 className='pt-4 ps-3'> For GST </h6>
   <li> <Link to='https://services.gst.gov.in/services/quicklinks/registration' target='_blank'> Online GST Registration  </Link> </li>
  <li>  <Link to='https://services.gst.gov.in/services/quicklinks/payments'target='_blank'> Pay GST Online </Link> </li>
  <li> <Link to='https://www.gst.gov.in/' target='_blank'> Search GST Dealer </Link> </li>
<li> <Link to='https://services.gst.gov.in/services/login'> Pay GST Return Online  </Link> </li>
  </div>

  <div className='col-xl-3 col-lg-3 col-12'> 
  <h6 className='pt-4 ps-3'> For ROC/MCA </h6>
 
  <li> <Link to='https://www.mca.gov.in/mcafoportal/login.do' target='_blank'> Login To MCA V2 Portal  </Link>  </li>
  <li> <Link to='https://www.mca.gov.in/content/mca/global/en/foportal/fologin.html' target='_blank'> Login To MCA V3 Portal  </Link>  </li>
  <li>  <Link to='https://www.mca.gov.in/MinistryV2/registerdsc.html' target='_blank'> Register Your DSC on MCA </Link> </li>
      <li> <Link to='https://www.mca.gov.in/mcafoportal/showCheckCompanyName.do' target='_blank'> Search Name of Company </Link> </li>
      <li> <Link to='https://www.mca.gov.in/MinistryV2/aboutmasterdata.html' target='_blank'> Check Company Master Data </Link> </li>
      <li>  <Link to='https://www.mca.gov.in/mca/html/mcav2_en/home/mcaservice/masterdata/viewsignatorydetails/view+signatory+details.html' target='_blank'> Check Signatory Details </Link> </li>
      <li> <Link to='https://www.mca.gov.in/mca/html/mcav2_en/home/mcaservice/masterdata/viewsignatorydetails/view+signatory+details.html' target='_blank'> Company Forms Download </Link> </li>
      <li> <Link to='https://www.mca.gov.in/mca/html/mcav2_en/home/mcaservice/efiling/llp+forms+download/llpformsdownload.html' target='_blank'> LLP Forms Download </Link> </li>
      <li> <Link to='https://www.mca.gov.in/mcafoportal/showCheckFilingStatus.do' target='_blank'> Check Annual Filings Status </Link> </li>
                </div>

                <div className='col-xl-3 col-lg-3 col-12'> 
  <h6 className='pt-4 ps-3'> PF & ESI </h6>
 
  <li> <Link to='https://unifiedportal-emp.epfindia.gov.in/epfo/'> Unified Portal Login  </Link>  </li>
                  <li>  <Link to='https://unifiedportal-emp.epfindia.gov.in/epfo/' target='_blank'> Pay PF Online </Link> </li>
      <li> <Link to='https://esic.gov.in/' target='_blank'> ESI Login </Link> </li>
      <li> <Link to='https://passbook.epfindia.gov.in/MemberPassBook/login' target='_blank'> Know Your PF Balance </Link> </li>
      <li> <Link to='https://www.esic.in/EmployerPortal/ESICInsurancePortal/Portal_Loginnew.aspx' target="_blank"> ESI Login </Link> </li>
                </div>

</div>
  </ul>
              </li>
            </ul>
          </div>
       </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default NavbarTwo;
