import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle, } from "react-icons/fa";

const FilingItr3 = () => {
  return (
    <>
    
<NavbarTwo/>
    <Breadcrumb title={"Filing ITR 3"} />


  {/* ===================== Service Details Area start ===================== */}
  <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
         <div className='col-lg-12'>
                    <h4> ITR-3 </h4>
<p> The ITR-3 form is to be used by those individuals/ HUF who conduct a business or profession. It is also to be used by partner in a firm. Along with the income earned from business/ profession, taxpayers can also club any income they receive from windfalls, speculation, salaries, lotteries, housing properties etc. with their business income. </p>
<p> This form is applicable for the following: </p>
<ul className='single-list-inner style-check style-check mb-3'>
                        <li> <FaCheckCircle />  Individuals who earn income from any business/ profession </li>
        <li> <FaCheckCircle /> Individuals who earn income from housing property </li>
        <li> <FaCheckCircle /> Individuals who earn capital gains income </li>
        <li> <FaCheckCircle /> Individuals whose income from agriculture is aboveRs. 5,000 </li>
        <li> <FaCheckCircle /> Individuals who own any assets or property in countries other than India </li>
        <li> <FaCheckCircle /> Individuals who earn income from any country outside India </li>                    
                        </ul>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}


   
    <FooterOne/>
    </>
  )
}

export default FilingItr3