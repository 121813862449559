import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle, } from "react-icons/fa";

const FilingGSTR1 = () => {
  return (

<>

 
<NavbarTwo/>
        <Breadcrumb title={"GSTR-1"} />
    
    
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> GSTR-1 </h4>
<p> The taxpayer records all his outward supplies of goods and services in details in this form. This has to be mandatorily done by the 10th of the next month. This will form the basis of all future flow and match for credit reconciliations. GSTR-1 is a detailed form containing 13 different heads. The critical headings are: </p>
  
                            <ul className='single-list-inner style-check style-check mb-3'>
                            <li> <FaCheckCircle /> Firms </li>
        <li> <FaCheckCircle /> GSTIN of the Taxable Person – Auto populated result </li>
        <li> <FaCheckCircle /> Name – Auto populated result </li>
        <li> <FaCheckCircle /> Gross Turnover in Last Financial Year – This has to be filed only once. From next year onwards, this field will be auto populated </li>
        <li> <FaCheckCircle /> The Period for which the return is being filed – Month & Year shall be available as a drop down for selection </li>
        <li> <FaCheckCircle /> Taxable outward supplies – Here, IGST shall be filled only in the case of inter-state movement whereas CGST and SGST shall be filled in case of intra-state movement. Moreover, details of any exempted sales or sale at nil rate of tax shall also be mentioned here </li>
        <li> <FaCheckCircle /> Outward Supplies to end customer, where the value exceeds Rs. 2.5 lakhs – Other than mentioned, all such supplies are optional in nature </li>  
        <li> <FaCheckCircle /> Any other supplies not covered in above 2 sections  </li>
        <li> <FaCheckCircle /> Debit Notes or Credit Notes Details </li>  
        <li> <FaCheckCircle /> Amendments to the details of any outward supplies of previous periods – This does not covers any changes by way of debit/credit notes  </li>                       
        <li> <FaCheckCircle /> Exempted, Nil-Rated and Non-GST Supplies – This is a Non-GST section. When the details of exempted sales or nil-rated sales have already been mentioned anywhere above, then only Non-GST shall be filled up here </li>  
        <li> <FaCheckCircle /> Export Sales </li>  
        <li> <FaCheckCircle /> Tax Liability arising out of advance receipts  </li>  
        <li> <FaCheckCircle /> Tax Paid </li>  
                            </ul>
                         
                  
                </div>
              </div>
            </div>
          </div>
          {/* ===================== Service Details Area End ===================== */}
    
    
       
        <FooterOne/>
   

</>
  )
}

export default FilingGSTR1