import React from "react";

const WorkProcessOne = () => {
  return (
    <>
      {/*==================== Work Process One start ====================*/}
      <div className='work-process-area pd-top-120 pd-bottom-90 '>
        <div className='container'>
          <div className='section-title text-center'>
       
            <h2 className='title'>
           Our Services
            </h2>
          </div>
          <div className='row'>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/gst-return.png' alt='img' />
                </div>
                <div className='details'>
                
                  <h5 className='mb-3'> GST Returns  </h5>
                  <p className='content'>
                    Research ipsum dolor sit consec tetur sed diam in the
                    aliquam tempor
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/incometax-return.png' alt='img' />
                </div>
                <div className='details'>
                
                  <h5 className='mb-3'> Income Tax Returns </h5>
                  <p className='content'>
                    Create ipsum dolor sit consec tetur sed diam in the aliquam
                    tempor
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/payroll.png' alt='img' />
                </div>
                <div className='details'>
                  
                  <h5 className='mb-3'> Payroll </h5>
                  <p className='content'>
                    Develope ipsum dolor sit consec tetur sed diam in the
                    aliquam tempor
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/tax-compliances.png' alt='img' />
                </div>
                <div className='details'>
                
                  <h5 className='mb-3'> Tax Compliances </h5>
                  <p className='content'>
                    Shop ipsum dolor sit consec tetur Malesuada sed diam in the
                    aliquam tempor
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process One end ====================*/}
    </>
  );
};

export default WorkProcessOne;
