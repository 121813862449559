import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle,  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const ShopAndEstablishmentRegistration = () => {
  return (
   <>
   
  <NavbarTwo/>
        <Breadcrumb title={"Shop and Establishment Registration"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> Shop and Establishment Registration </h4>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Mandatory requirement for any shop or commercial establishment operating in Delhi NCR. </li>
        <li> <FaCheckCircle /> Applicable for shops and establishment in entire NCR </li>
        <li> <FaCheckCircle /> Give some minimum benefits and relief to the vast unorganized sector of employees, employed in Shops and Establishments. Industrial Dispute Act 1947, and Delhi Shops & Establishments. Act, 1954 are supplemental to each other. </li> 
                                  </ul>
                           </div>
 <div className='col-lg-12 col-12 text-center'>
                      <h4 className='my-5'> Time involved in the process </h4>
                    </div>
                    <div className='col-lg-12 col-md-12'> <p> <FaCheckCircle className='blue-icon' />  Certificate may be downloaded on same day after filing online application. </p></div>
    <div className='col-lg-12 col-12 my-5'>
                     <h4 className='text-center'> Document Required for Shop and Establishment </h4>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Name of establishment </li>
        <li> <FaCheckCircle /> Category of establishment i.e  shop, commercial establishment, residential hotel, restaurant, theatre, public amusement, retail trade or business </li>
        <li> <FaCheckCircle /> Address of establishment with pin code </li>
        <li> <FaCheckCircle /> Telephone no, mobile no, fax no. </li>
        <li> <FaCheckCircle /> Email id, website </li>
        <li> <FaCheckCircle /> Name of occupier/ employer </li>
        <li> <FaCheckCircle /> Father's name of occupier/ employer </li>
        <li> <FaCheckCircle /> Name Of Manager  </li>
        <li> <FaCheckCircle /> Father's name of manager </li>
        <li> <FaCheckCircle /> Nature of business- provide list of items for trading  </li>
        <li> <FaCheckCircle /> No. of employees bifurcating between no. of male, no. of female , no of  young employee and  total no of employee </li>
        <li> <FaCheckCircle /> Date of commencement of establishment </li>
        <li> <FaCheckCircle /> No. of members of employers family working in the establishment. their name and age </li>
        <li> <FaCheckCircle /> No. of other persons occupying position of management or employees engaged in confidential capacity, their name and age. </li>
        
                                  </ul>
                    </div>




                     {/* CTA START */}

<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> Shop and Establishment Registration </h5>
                </div>
                <div>
                  <p> Starts from </p>
                  <h5><img src='../assets/img/inr.png' alt='Indian Rupee' className='img-fluid' /> 3,000/- </h5>
                  <p> All inclusive fees </p>
                </div>
   <div>
                  <Link to='/contact' className='btn btn-primary'> Enquire Now </Link>
                </div>
              </div>
  </div>

  {/* CTA END */}

               </div>
            </div>
          </div>

         
 <FooterOne/>




   </>
  )
}

export default ShopAndEstablishmentRegistration