import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle, } from "react-icons/fa";

const FilingItr5 = () => {
  return (
    <>
    
    <NavbarTwo/>
        <Breadcrumb title={"Filing ITR 5"} />
    
    
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> ITR-5 </h4>
    <p> The ITR-5 form is to be used only by the following entities for filing income tax returns: </p>
  
                            <ul className='single-list-inner style-check style-check mb-3'>
                            <li> <FaCheckCircle /> Firms </li>
        <li> <FaCheckCircle /> Limited Liability Partnerships (LLPs) </li>
        <li> <FaCheckCircle /> Body of Individuals (BOIs) </li>
        <li> <FaCheckCircle /> Association of Persons (AOPs) </li>
        <li> <FaCheckCircle /> Co-operative Societies </li>
        <li> <FaCheckCircle /> Artificial Judicial Persons </li>
        <li> <FaCheckCircle /> Local Authorities </li>                       
                            </ul>
                         
                  
                </div>
              </div>
            </div>
          </div>
          {/* ===================== Service Details Area End ===================== */}
    
    
       
        <FooterOne/>
        </>
  )
}

export default FilingItr5