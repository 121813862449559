import React from "react";


const OurVisions = () => {
    
  return (
   <>

     <div className='about-area  pd-top-120 pd-bottom-90 bgbanner'>
        <div className='container'>
          <div className='z-index-2 border-radius-5 p-xl-5 p-4'>
            <div className='row'>
              <div className='col-lg-6'>
 <div className='about-thumb-inner pe-xl-5 pb-5 me-xl-4'  data-aos='fade-right'
    data-aos-delay='200' data-aos-duration='1500' >
<img className='main-img radius' src='assets/img/about/ourvision2.jpg' alt='img' />
                
                </div>
               
              </div>
              <div
                className='col-lg-6'
                data-aos='fade-left'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <div className='section-title mt-5 mt-lg-0 mb-0'>
                  
                  <h2 className='title pt-5'> Our Vision </h2>
<p className='content'> At Simplitax, we aim to serve with a high quality, dedicated and result - oriented execution team to the clients and serve as a primary resource and partner in all aspects of clients' business growth and development; further we shall get involved & have a sincere concern with our clients to understand their objectives and meet their expectations from Simplitax </p>
    </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

      <div className='about-area  pd-top-120 pd-bottom-90 bg-white'>
        <div className='container'>
          <div className='z-index-2 border-radius-5 p-xl-5 p-4'>
            <div className='row'>

            <div
                className='col-lg-6'
                data-aos='fade-left'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <div className='section-title mt-5 mt-lg-0 mb-0'>
                  
                  <h2 className='title pt-5'> Our Mission </h2>
<p className='content'>   To provide businesses, entrepreneurs and individuals with the highest quality accounting, auditing, tax planning and business advisory services delivered in a timely, efficient and innovative manner by a professional team that clearly enjoys working together to exceed their clients' needs. </p>
    </div>
              </div>

              <div className='col-lg-6'>
 <div className='about-thumb-inner pe-xl-5 pb-5 me-xl-4'  data-aos='fade-right'
    data-aos-delay='200' data-aos-duration='1500' >
<img className='main-img' src='assets/img/about/ourvision1.jpg' alt='img' />
                
                </div>
               
              </div>
             
            </div>
          </div>
        </div>
      </div>

   </>
  )
}

export default OurVisions