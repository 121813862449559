import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle, } from "react-icons/fa";
import { Link } from "react-router-dom";

const FilingItr2 = () => {
  return (
   <>

<NavbarTwo/>
    <Breadcrumb title={"Filing ITR 2"} />


  {/* ===================== Service Details Area start ===================== */}
  <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
         <div className='col-lg-12'>
                    <h4> ITR-2 </h4>
<p> The ITR 2 form should be used by Individuals/ HUF who have Income from Capital Gains or Salary / Other Income more than 50 Lacs. </p>
<p> The ITR-2 form is applicable for the following: </p>
                    <div className='row'>
                      <div className='col-md-12'>
                        <ul className='single-list-inner style-check style-check mb-3'>
    <li> <FaCheckCircle /> Individuals who earn income through salary or pension above 50 Lacs </li>
<li> <FaCheckCircle /> Individuals who earn income from more than one housing property </li>
<li> <FaCheckCircle /> Individuals who have Capital Gains income from sale of Shares/ property etc. </li>
                          <li> <FaCheckCircle />  Individuals who own any assets or property in countries other than India  </li> 
                          <li> <FaCheckCircle /> Individuals who earn income from any country outside India  </li>  
                          <li> <FaCheckCircle /> Individuals who do not earn any income from any business/ profession  </li>  
                          <li> <FaCheckCircle /> Individuals who are partners in a Partnership Firm.  </li>                           
                        </ul>
                      </div>
                     
                    </div>
              
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}

  {/* ========================= contact Area One start =========================*/}
  <div className='contact-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='contact-inner-1'>
            <img
              className='top_image_bounce animate-img-1'
              src='assets/img/banner/2.png'
              alt='img'
            />
            <img
              className='top_image_bounce animate-img-2'
              src='assets/img/about/6.png'
              alt='img'
            />
            <div className='row'>
              <div
                className='col-lg-8'
                data-aos='fade-right'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <img className='w-100' src='assets/img/bg/4.png' alt='img' />
              </div>
  <div className='col-lg-4 wow animated fadeInRight' data-aos='fade-left' data-aos-delay='200'
  data-aos-duration='1500'>
                <div className='section-title mb-0'>
           <h2 className='title'> Get in Touch with us </h2>
                  <p className='content'>
                    For your car we will do everything advice design in us
                    repairs and maintenance. We are the some preferred.
                  </p>
                  <form className='mt-4'>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <div className='single-input-inner style-border'>
                          <input type='text' placeholder='Your Name' />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='single-input-inner style-border'>
                          <input type='text' placeholder='Your Email' />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='single-input-inner style-border'>
                          <input type='text' placeholder='Your Phone' />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='single-input-inner style-border'>
                          <input type='text' placeholder='Your Subject' />
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <div className='single-input-inner style-border'>
                          <textarea placeholder='Message' defaultValue={""} />
                        </div>
                      </div>
                      <div className='col-12'>
  <Link className='btn btn-black mt-0 w-100 border-radius-5' href='#' > Submit now </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*========================= contact-inner One end =========================*/}

   
    <FooterOne/>
   
   </>
  )
}

export default FilingItr2