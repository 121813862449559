import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-120 bgbanner'>
        <div className='container'>
          <div className='row'>
        
            <div
              className='col-lg-12 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0 pb-5'>
             <h2 className='title text-center'> About us </h2>
  <p className='content '> SimpliTax CMS Pvt Ltd. is a Tax Advisory & Financial Consulting company, fully managed by specialized professionals who are expert in their respective fields. It has been founded with a vision to provide one stop solutions to business, tax, regulatory, legal and allied consulting needs of clients from India and multinational companies operating/wanting to operate, in India.
                </p>
                <p> Exceptional client service is what separates us from the rest. </p>
                <p> We ask. We listen. We hear you loud and clear and provide end to end solutions! </p>
                <p> Learning about you, your organization, and your expectations are critical to our relationship because your definition of satisfaction is the only definition that matters. </p>
                <p> We have dedicated team comprising of Chartered Accountants, Company Secretaries, CPAs, MBA and Tax Advocates. </p>
           
              </div>
            </div>

           


          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
