import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle,  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const PartnershipFirmRegistration = () => {
  return (
   <>
<NavbarTwo/>
        <Breadcrumb title={"Partnership Firm Registration"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> Partnership Firm Registration </h4>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Easy to form a partnership firm </li>
        <li> <FaCheckCircle /> Any name may be chosen </li>
        <li> <FaCheckCircle /> Can be registered as well as unregistered. </li>
        <li> <FaCheckCircle /> Ideal for small business in unorganized sector. However, LLP has more advantage than firms </li>
        <li> <FaCheckCircle /> Less statutory compliances as compared to Companies and LLP </li>
                                  </ul>
                           </div>
                    <div className='col-lg-12 col-12 text-center'>
                      <h4 className='my-5'> Inclusions in this package </h4>
                    </div>
                    <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' /> Partnership Deed executed on non-judicial stamp paper and notarized </p></div>
                     <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  PAN / TAN Registration </p></div>
                       <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Firm’s Stamp </p></div>
                         <div className='col-lg-6 col-md-6'> <p> <img src='../assets/img/book-icon.png' className='inclusions-icons' alt='include-icon' />  Assistance in opening current account in Bank </p> </div>
                         <div className='col-lg-12 col-12 text-center'>
                      <h4 className='my-5'> TIME INVOLVED IN THE PROCESS </h4>
                    </div>
                    <div className='col-lg-6 col-md-6'> <p> <FaCheckCircle className='blue-icon' />  Approx. 2 working days for drafting and execution of proprietorship </p></div>
                     <div className='col-lg-6 col-md-6'> <p> <FaCheckCircle className='blue-icon' />   Approx. 10 working days in getting PAN</p></div>
                     <div className='col-lg-12 col-12 my-5'>
                     <h4 className='text-center'> Information Required for forming a Partnership Firm </h4>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Name, Father's Name, Date of birth, PAN no. and residential address of all partners </li>
        <li> <FaCheckCircle /> Name and address of proposed partnership </li>
        <li> <FaCheckCircle /> Nature of business of partnership </li>
        <li> <FaCheckCircle /> Profit Sharing ratio among partners </li>
        <li> <FaCheckCircle /> Amount of capital with which partnership firm has to be registered. </li>
                                  </ul>
                    </div>
{/* CTA START */}
<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> Partnership Registration </h5>
                </div>
                <div>
                  <p> Starts from </p>
                  <h5><img src='../assets/img/inr.png' alt='Indian Rupee' className='img-fluid' /> 2500/- </h5>
                  <p> All inclusive fees </p>
                </div>
   <div>
                  <Link to='/contact' className='btn btn-primary'> Enquire Now </Link>
                </div>
              </div>
  </div>

  {/* CTA END */}
  


                   
   </div>
            </div>
          </div>

          <div
        className='faq-area  bg-cover pd-top-90 pd-bottom-110'
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className='container'>
          <div className='row'>
 <div  className='col-xl-12 col-lg-12'
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-4 mt-lg-0'>
              <h2 className='title text-center'> FAQS </h2>
              
              </div>
              <div
                className='accordion accordion-inner style-2 accordion-icon-left mt-3'
                id='accordionExample'
              >
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    > At what rate profit from Partnership is taxed in India?
                    </button>
                  </h2>
  <div id='collapseOne' className='accordion-collapse collapse show' aria-labelledby='headingOne'
        data-bs-parent='#accordionExample' >
                    <div className='accordion-body'> Partnership firm is taxable @30% </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    > How many Partners are required for starting a Partnership Firm?
                    </button>
                  </h2>
<div id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'> Minimum 2 partners are required. There is no limit for maximum number of Partners. Minors cannot be partner in a firm.
                    </div>
                  </div>
                </div>



                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseThree' aria-expanded='false'
                      aria-controls='collapseThree'
                    > What is the minimum capital with which a partnership firm can be started?
                    </button>
                  </h2>
                  <div
                    id='collapseThree'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingThree'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    No limit. Can be started with even Rs 10,000.
                    </div>
                  </div>
                </div>

   <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFour'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingFour' aria-expanded='false'
                      aria-controls='collapseheadingFour'
                    > What are the Statutory Compliances that Partnership Firm and Partners has to comply?
                    </button>
                  </h2>
                  <div
                    id='collapseheadingFour'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingheadingFour'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <div className='row'>
                        <div className='col-lg-12 col-md-12 col-12'>
                        <h5> With Income Tax Department: </h5>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Partnership firm and Partners have to file Income Tax Return annually </li>
        <li> <FaCheckCircle /> Advance tax needs to be paid periodically </li>
        <li> <FaCheckCircle /> Tax audit has to be got done by CA and audit report needs to be collected in case annual turnover is more than Rs 1 crore in case of trading business or Rs 50 lakh in case of professional business. </li>
                                  </ul>
                                  <h5> Others </h5>
                     <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> GST returns to be filed online monthly </li>
        <li> <FaCheckCircle /> TDS returns of Salary paid and vendor payment is to be filed quarterly, if applicable  </li>
        <li> <FaCheckCircle /> Other need based compliances </li>
                                  </ul>

                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>

  <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFive'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseheadingFive' aria-expanded='false'
      aria-controls='collapseheadingFive'
  >  Do I need to file returns and do other compliance even if there is no operations in the Partnership Firm? </button>
                  </h2>
                  <div
                    id='collapseheadingFive'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingheadingFive'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <p> Every partnership firm has to prepare balance sheet and profit and loss account and file Income tax return, even if there is no transaction or there is loss. </p>
  </div>
                  </div>
                </div>
             </div>
            </div>
          </div>
        </div>
  </div>
 <FooterOne/>
   
   </>
  )
}

export default PartnershipFirmRegistration