import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle, } from "react-icons/fa";

const FilingItr1 = () => {
  return (
    <>

<NavbarTwo/>
        <Breadcrumb title={"Filing ITR 1"} />
    
    
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
             <div className='col-lg-12'>
                        <h4> ITR-1 </h4>
    <p> This income tax return is a simple One Page Form for individuals having Total Income upto 50 Lacs. </p>
    <p> This Return form is to be filed by: </p>
                    <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> Individuals who earn income through Salary or Pension or Bank interest  </li>
        <li> <FaCheckCircle /> Individuals who earn income from a single housing property </li>
        <li> <FaCheckCircle /> Individuals who do not have any business income </li>
        <li> <FaCheckCircle /> Individuals who do not have any capital gains income/ loss </li>
        <li> <FaCheckCircle /> Individuals who do not own any assets or property in countries other than India </li>
        <li> <FaCheckCircle /> Individuals who do not earn income from any country outside India </li>
                              
                            </ul>
                         
                  
                </div>
              </div>
            </div>
          </div>
          {/* ===================== Service Details Area End ===================== */}
    
    
       
        <FooterOne/>
   

        </>
  )
}

export default FilingItr1