import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import CaseStudyDetails from "./pages/CaseStudyDetails";
import Contact from "./pages/Contact";
import HomeFour from "./pages/HomeFour";
import HomeOne from "./pages/HomeOne";
import HomeThree from "./pages/HomeThree";
import HomeTwo from "./pages/HomeTwo";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import HomeFive from "./pages/HomeFive";
import OurVision from "./pages/OurVision";
import FilingItr1 from "./pages/FilingItr1";
import FilingItr2 from "./pages/FilingItr2";
import FilingItr3 from "./pages/FilingItr3";
import FilingItr4 from "./pages/FilingItr4";
import FilingItr5 from "./pages/FilingItr5";
import FilingItr6 from "./pages/FilingItr6";
import FilingItr7 from "./pages/FilingItr7";
import PayrollOverview from "./pages/PayrollOverview";
import GstReturnandTheirDueDates from "./pages/GstReturnandTheirDueDates";
import FilingGSTR1 from "./pages/FilingGSTR1";
import ProprietorshipRegistration from "./pages/ProprietorshipRegistration";
import PartnershipFirmRegistration from "./pages/PartnershipFirmRegistration";
import LlpRegistration from "./pages/LlpRegistration";
import OnePersonCompanyRegistration from "./pages/OnePersonCompanyRegistration";
import PrivateLimitedCompanyRegistration from "./pages/PrivateLimitedCompanyRegistration";
import PublicLimitedCompanyRegistration from "./pages/PublicLimitedCompanyRegistration";
import ForeignCompanyRegistration from "./pages/ForeignCompanyRegistration";
import ShopAndEstablishmentRegistration from "./pages/ShopAndEstablishmentRegistration";
import Section8Company from "./pages/Section8Company";
import SocietyRegistration from "./pages/SocietyRegistration";
import TrustRegistration from "./pages/TrustRegistration";
import GstRegistration from "./pages/GstRegistration";
import PfRegistration from "./pages/PfRegistration";
import EsiRegistration from "./pages/EsiRegistration";
import IecRegistration from "./pages/IecRegistration";
import DscRegistration from "./pages/DscRegistration";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomeOne />} />
        <Route exact path='/index-2' element={<HomeTwo />} />
        <Route exact path='/our-vision' element={<OurVision />} />
        <Route exact path='/index-3' element={<HomeThree />} />
        <Route exact path='/index-4' element={<HomeFour />} />
        <Route exact path='/index-5' element={<HomeFive />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/blog' element={<Blog />} />
        <Route exact path='/blog-details' element={<BlogDetails />} />
        <Route exact path='/filing-itr-1' element={<FilingItr1/>}></Route>
        <Route exact path='/filing-itr-2' element={<FilingItr2/>}></Route>
        <Route exact path='/filing-itr-3' element={<FilingItr3/>}></Route>
        <Route exact path='/filing-itr-4' element={<FilingItr4/>}></Route>
        <Route exact path='/filing-itr-5' element={<FilingItr5/>}></Route>
        <Route exact path='/filing-itr-6' element={<FilingItr6/>}></Route>
        <Route exact path='/filing-itr-7' element={<FilingItr7/>}></Route>
        <Route exact path='/gst-return-and-their-due-dates' element={<GstReturnandTheirDueDates />}> </Route>
        <Route exact path='/filing-gstr1' element={<FilingGSTR1 /> }></Route>
        <Route exact path='/proprirtorship-registration' element={<ProprietorshipRegistration />}> </Route>
        <Route exact path='/partnership-firm-registration' element={<PartnershipFirmRegistration />}> </Route>
        <Route exact path='/LLP-registration' element={<LlpRegistration />}> </Route>
        <Route exact path='/one-person-company-registration' element={<OnePersonCompanyRegistration />}> </Route>
        <Route exact path='/private-limited-company-registration' element={<PrivateLimitedCompanyRegistration/>}></Route>
        <Route exact path='/public-limited-company-registration' element={<PublicLimitedCompanyRegistration/> }> </Route>
        <Route exact path='/foreign-company-registration' element={<ForeignCompanyRegistration /> }></Route>
        <Route exact path='/shop-and-establishment-registration' element={<ShopAndEstablishmentRegistration/>}> </Route>
        <Route exact path='/section-8-company' element={<Section8Company /> }> </Route>
        <Route exact path='/society-registration' element={<SocietyRegistration />}> </Route>
        <Route exact path='/trust-registration' element={<TrustRegistration />}> </Route>
        <Route exact path='/gst-registration' element={<GstRegistration />}> </Route>
        <Route exact path='/pf-registration' element={<PfRegistration />}> </Route>
        <Route exact path='/esi-registration' element={<EsiRegistration />}> </Route>
        <Route exact path='/iec-registration' element={<IecRegistration/>}> </Route>
        <Route exact path='/dsc-registration' element={<DscRegistration />}> </Route>
        <Route exact path='/payroll-overview' element={<PayrollOverview/>}> </Route>
        <Route exact path='/case-study-details' element={<CaseStudyDetails />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/service' element={<Service />} />
        <Route exact path='/service-details' element={<ServiceDetails />} />
        <Route exact path='/team' element={<Team />} />
        <Route exact path='/team-details' element={<TeamDetails />} />
      </Routes>
      <ScrollToTop smooth color='#246BFD' />
    </BrowserRouter>
  );
}

export default App;
