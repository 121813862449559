import React from 'react'
import NavbarTwo from '../components/NavbarTwo'
import FooterOne from '../components/FooterOne'
import Breadcrumb from "../components/Breadcrumb";
import {  FaCheckCircle,  } from "react-icons/fa";
import { Link } from 'react-router-dom';

const GstRegistration = () => {
  return (
    <>
    
<NavbarTwo />
        <Breadcrumb title={"GST Registration"} />
      <div className='service-area pd-top-120 pd-bottom-90'>
            <div className='container'>
              <div className='row'>
              <div className='col-lg-12 qna_section'>
                        <h4 className='pb-4'> GST Registration </h4>
                        <h5> What is GST (Goods and Service Tax) ? </h5>
<p> From 1st July 2017, all the indirect taxes which imposed by the state or central government are set be replaced with GST (Goods and Service Tax) by July 2017. So from now its is mandatory requirements to convert the existing VAT/CST State wise Assessee and Service Tax or Excise assessee to the new GST Registration. </p>
<p> Even all the new entrepreneurs or assessee who cross turnover of 20 Lakh Rupees in a year, except the north eastern state where the limit for mandatory GST Registration is 10 lakhs. </p>
<h5> GST Registration Online Procedure </h5>
<p> Simplitax Team helps you to getting your GST Registration Number via GST Portal. GST Portal is user friendly but still you will require a professional's help to fill up your application forms and submission of the documents on the GST Portal. You should follow below mentioned steps :- </p>
 <ul className='single-list-inner style-check style-check mb-3'>
        <li> <FaCheckCircle /> First of all just login, on GST Portal. </li>
        <li> <FaCheckCircle /> Fill up the Part A of GST Registration Form-1. </li>
        <li> <FaCheckCircle /> Client will receive a reference number via email and mobile number. </li>
        <li> <FaCheckCircle /> Fill Up the second part of the registration form and upload the relevant documents as per the business entity. </li> 
        <li> <FaCheckCircle /> Finally a provisional Certificate of Registration under GST shall be issued by the Government of India. </li> 
                                  </ul>
<h5> GST Registration Number </h5>
<p> GST Registration Number is 15 Digit identification number which is allotted to each applicant. </p>
<p> It’s completely based on the Pan Number and State code. First two digit represent the state code and next 10 digits represent the Pan number of the client, one digit represent the entity code( Like proprietorship or partnership etc), one digit is left blank and last one is check digit. </p>
<h5> GST Registration Documents </h5>
<ul className='single-list-inner style-check style-check mb-3'>
<p>(A) In case of Sole Proprietorship Firm - </p>
<li> <FaCheckCircle /> Pan Card and Address Proof of the proprietor </li>
<li> <FaCheckCircle /> Passport size photograph of proprietor </li>
<li> <FaCheckCircle /> Recent 3 Months’ Bank Statement / Cancelled cheque (bearing IFSC code on statement) </li>
<li> <FaCheckCircle /> Rent Agreement / Electricity Bill Copy </li>
</ul>
<ul className='single-list-inner style-check style-check mb-3'>
<p>(B) In case of Partnership Firm - </p>
<li> <FaCheckCircle /> Pan card of the Firm with the partnership deed </li>
<li> <FaCheckCircle /> All Partners’ PAN card copy & address proofs </li>
<li> <FaCheckCircle /> Passport size photographs of all partners </li>
<li> <FaCheckCircle /> Recent 3 Months’ Bank Statement of authorized signatory or partnership firm/ Cancelled cheque (bearing IFSC code on statement) </li>
<li> <FaCheckCircle/> Rent Agreement / Electricity Bill Copy </li>
</ul>
<ul className='single-list-inner style-check style-check mb-3'>
<p>(C) In case of Private Limited Company or One Person Company - </p>
<li> <FaCheckCircle /> Pan Card of the Company , COI , MOA & AOA of Company. </li>
<li> <FaCheckCircle /> Board Resolution regarding GST registration with Directors’ PAN Card copy & Address Proof </li>
<li> <FaCheckCircle /> Passport size photographs of all partners </li>
<li> <FaCheckCircle /> Recent 3 Months’ Bank Statement of company or of authorized signatory’s a/c / Cancelled cheque (bearing IFSC code on statement) </li>
<li> <FaCheckCircle/> Rent Agreement / Electricity Bill Copy </li>
</ul>
<ul className='single-list-inner style-check style-check mb-3'>
<p>(D) In case of LLP (Limited Liability Partnership) - </p>
<li> <FaCheckCircle /> COI & Pan Card of Company. </li>
<li> <FaCheckCircle /> Partnership Deed + Partners Id & Address Proof </li>
<li> <FaCheckCircle /> Passport size photographs of all partners </li>
<li> <FaCheckCircle /> Recent 3 Months’ Bank Statement of authorized signatory or partnership firm/ Cancelled cheque (bearing IFSC code on statement) </li>
<li> <FaCheckCircle/> Rent Agreement / Electricity Bill Copy  </li>
</ul>
<h6><b> How to Migrate your Existing Service Tax Number into the GST Registration </b> </h6>
<p> If you want to convert your existing service tax number into the GST Number then you have to follow following steps for the GST Registration Online -</p>
<ol className='mb-3' type='a'>
    <li> Login on CBEC Portal. </li>
    <li> Get the Provisional id and password of the same. </li>
    <li> Generate the new user id and password on the GST portal. </li>
    <li> Fill Up your business details with the promoter details & business address, bank details and upload the necessary documents. </li>
    <li> Verify with the digital signature or Aadhar Card EVC. </li>
</ol>
<h6><b> How to Enroll New Fresh GST Registration Online </b> </h6>
<ul className='single-list-inner style-check style-check mb-3'>
    <p> Under GST there are 2 turnover limits:- </p>
    <li> <FaCheckCircle/> 20 Lakh Rupees in a year for across India except the North East State. </li>
    <li> <FaCheckCircle/> 10 Lakh Rupees in a year for North East State.(Arunachal Pradesh, Assam, Manipur, Meghalaya, Mizoram, Nagaland, Sikkim, Tripura, Himachal Pradesh and Uttarakhand) </li>
   
</ul>
<p> So its means if your business sales or turnover is more than the above limits then GST Registration is mandatory for you and if you are looking for the input tax credit then also GST Registration is required. </p>
<p> In Some cases we have to require the online gst registration from starting for doing our regular business because all other traders or suppliers are registered under the gst. so it's a complete chain from manufacture to retailer or wholesaler or service provider. </p>
                           </div>

                     {/* CTA START */}

<div className='col-xl-12  col-12 pt-4'>
    <div className='ctabox'>
            <div>
                 <h5> GST Registration </h5>
                </div>
                <div>
                  <p> Starts from </p>
                  <h5><img src='../assets/img/inr.png' alt='Indian Rupee' className='img-fluid' /> 2,000/- </h5>
                  <p> All inclusive fees </p>
                </div>
   <div>
                  <Link to='/contact' className='btn btn-primary'> Enquire Now </Link>
                </div>
              </div>
  </div>

  {/* CTA END */}

               </div>
            </div>
          </div>

 <FooterOne/>

    
    </>
  )
}

export default GstRegistration